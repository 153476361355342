import { tapSensorFragment } from "utilities"

export const getPPSubByIdQuery = /* GraphQL */ `
    query getPPSubById($input: inputGetByPPSubId!) {
        getPPSubById(input: $input) {
            __typename
            id
            hubSpotId
            title
            slug
            numberOfTapSensors
            onlyPriorityBrands            
            address {
                city
                country
                postalCode
                state
                street
                street2
		    }
            priorityBrands {
                id
                title
                assets {
                    path
                    ... on S3Asset {
                        __typename
                        path
                    }
                    ... on CloudinaryAsset {
                        __typename
                        cloudinaryId
                    }
                }
                products {
                    title
                    id
                    assets {
                        path
                        type
                    }
                    brand {
                        id
                        title
                        displayTitle
                        slug
                    }  
                    abv
                    slug
                    ... on Beer {
                        srm
                        ibu
                        style {
                            id
                            title
                            category {
                                title
                            }
                        }
                    }
                }
            }
            tapSensors {
                ...${tapSensorFragment}
            }
            settings {
                batteryReplacementPlan
                coolers
                couplers
                defaultServingSize
                freshnessThreshold
                inventoryTurnsGoal
                newProductDefinition
                pricingMethod
                pricingPercentage
                productLifeTracking
                servingSizes
                varietyScoreGoal
                tappedGoal
                timeZone
                standardHours {
                    day
                    isOpen
                    hours
                }
                workWeekStartDay
            }
            createdDate
            expirationDate
            updatedDate
        }
    }
`
