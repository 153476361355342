import type { Brand, BrandAssociation, UpdateBrand } from "types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { brandApi } from "../../api"
import { blxQ } from "../store"

export interface IBrandState {
    brandId: string
    brand: Brand
    brandFormData: UpdateBrand
}

export enum BrandFormFields {
    Title = "title",
    DisplayTitle = "displayTitle",
    Description = "description",
    YearEstablished = "yearEstablished",
    Traits_BrewTypes = "traits.brewTypes",
    Traits_BrandType = "traits.brandTypes",
    Website = "website",
    SocialMedia = "socialMedia",
    Contact_Email = "contact.email",
    Contact_Phone = "contact.phone",
    Address_Street = "address.street",
    Address_Street2 = "address.street2",
    Address_City = "address.city",
    Address_State = "address.state",
    Address_PostalCode = "address.postalCode",
    Address_Country = "address.country",
    Logo = "assets"
}

export interface IBrandActions {
    setBrandId: ({ brandId }: { brandId: string }) => void
    setBrandFormData: ({ brandFormData }: { brandFormData: UpdateBrand }) => void
    resetBrandState: () => void
    populateBrandState: ({ brand }: { brand?: Brand }) => void
}

const initialValues: IBrandState = {
    brandId: "",
    brand: null,
    brandFormData: null
}

export const brandState = create<IBrandState & IBrandActions>()(
    devtools(
        (set, get) => ({
            ...initialValues,
            setBrandId: ({ brandId }) => {
                set({ brandId })
            },
            setBrandFormData: ({ brandFormData }) => {
                set({ brandFormData })
            },
            populateBrandState: ({ brand }) => {
                set({
                    brand,
                    brandId: brand?.id
                })
            },
            resetBrandState: () => {
                set({ ...initialValues })
            }
        }),
        { name: "brandState" }
    )
)

export const brandActions = {
    getBrand: brandState.getState().brand,
    setBrand: brandState.getState().setBrandId,
    setBrandFormData: brandState.getState().setBrandFormData,
    populateBrandState: brandState.getState().populateBrandState,
    resetBrandState: brandState.getState().resetBrandState
}

export const getUserAssociatedBrands = async (
    brandAssociations: BrandAssociation[]
): Promise<Brand[]> => {
    const brands: Brand[] = []

    await Promise.all(
        brandAssociations.map(async (brand) => {
            const { data, error, isError, isSuccess } = await blxQ(
                brandApi.endpoints.getAssociatedBrand.initiate({
                    itemId: brand?.brandId
                })
            )
            if (isSuccess) {
                return brands.push(data)
            }
            if (isError) {
                console.log(error)
            }
        })
    )
    return brands.filter((item) => item?.id)
}
