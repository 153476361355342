export const getLocations = /* GraphQL */ `
query GetBrandById($brandId: ID!) {
    getBrandById(brandId: $brandId) {
        locations {
            brand {
                id
                slug
            }
            id
            slug
            title
            description
            address {
                street
                street2
                city
                state
                country
                postalCode
            }
            contact {
                phone
                email
            }
            traits {
                status
                type
                isProductionFacility
                foodOptions
                seatingOptions
                isPrimary
                foodOptions
                seatingOptions
                gameActivity
                miscActivity
                entertainmentActivity
                educationalActivity
                physicalActivity
                patronOptions
                tourInfo {
                    mustBeLegalDrinkingAge
                    cost
                    hours {
                        day
                        isOpen
                        hours
                    }
                }
            }
            hours {
                day
                isOpen
                hours
            }
            timezone
            yearOpened
            yearClosed
            updatedDate
            createdDate
        }
    }
  }
`
