"use client"
import {
    Alert,
    AlertDescription,
    AlertTitle,
    Box,
    CloseButton,
    Icon,
    SlideFade
} from "@chakra-ui/react"
import * as HeroOutline from "@heroicons/react/outline"
import classNames from "classnames"
import React from "react"
import { toastActions } from "../state/useToasts"
import type { IToast } from "../types/IToast"

const Toast: React.FC<IToast> = ({
    id,
    title = "",
    description,
    variant,
    duration,
    hasCloseButton,
    twClasses
}) => {
    const [visible, setVisible] = React.useState<boolean>(true)
    const removeToast = toastActions.removeToast
    const icon =
        variant === "success"
            ? HeroOutline.CheckCircleIcon
            : variant === "error"
              ? HeroOutline.XCircleIcon
              : null
    const handleRemoveToast = () => {
        setVisible(false)
        setTimeout(() => {
            removeToast(id)
        }, 350)
    }
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false)
        }, duration - 350)
        return () => clearTimeout(timer)
    }, [])

    return (
        <SlideFade in={visible} offsetY={12} reverse={true}>
            <Alert variant='default' w='full' maxW='prose' className={classNames(twClasses)}>
                {icon && (
                    <Icon
                        as={
                            variant === "success"
                                ? HeroOutline.CheckCircleIcon
                                : HeroOutline.XCircleIcon
                        }
                        color='light'
                        _mediaDark={{
                            color: "dark"
                        }}
                        fontSize='2xl'
                    />
                )}
                <Box w='full' display='flex' alignItems='center' justifyContent='space-between'>
                    <Box display='flex' alignItems='center'>
                        {title && <AlertTitle>{title}</AlertTitle>}
                        {description && <AlertDescription>{description}</AlertDescription>}
                    </Box>
                    {hasCloseButton && (
                        <CloseButton
                            justifySelf='flex-end'
                            onClick={handleRemoveToast}
                            color='light'
                            _mediaDark={{ color: "dark" }}
                        />
                    )}
                </Box>
            </Alert>
        </SlideFade>
    )
}
export default React.memo(Toast)
