export const getBrewMenusByBMSubIdQuery = /* GraphQL */ `query GetBrewMenusByBMSubId($input: inputByBMSubId!) {
  getBrewMenusByBMSubId(input: $input) {
    items {
      activeDates
      createdBy
      createdDate
      displays {
        createdBy
        createdDate
        displayTitle
        displayType
        id
        lastUpdatedBy
        menu {
          id
          title
        }
        slug
        status
        title
        updatedDate
      }
      id
      lastUpdatedBy
      locationId
      ppSubId
      status
      title
      updatedDate
      sections {
        id
        title
        menuItems {
          id
        }
      }
    }
    nextToken
    returnedCount
  }
}`
