export const getOrganizationGroupsQuery = /* GraphQL */ `
query GetOrganizationGroups($input: inputGetOrganizationGroups!) {
	getOrganizationGroups(input: $input){
    __typename
    bmSubId
    brandId
    cloudinaryAssetId
    id
    ppSubId
    slug
    title
  }
}`
