export const getTapSensorByIdQuery = /* GraphQL */ `
    query getTapSensorById($input: inputGetByPPSubId!) {
        getTapSensorById(input: $input) {
            id
            barTitle
            tapNumber
            assignedKegs {
                actualKickDate
                cooler
                coupler
                currentFluidOz
                id
                initialFluidOz
                kegStatus
                isTemporary
                receivedDate
                lastUpdatedBy
                assignedPositionIndex
                product {
                    __typename
                    id
                    title
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                    brand {
                        id
                        title
                        displayTitle
                        assets {
                            __typename
                            id
                            alt
                            type
                            caption
                            ... on S3Asset {
                                path
                            }
                            ... on CloudinaryAsset {
                                cloudinaryId
                            }
                        }
                    }
                    ... on UnverifiedProduct {
                        brandName
                    }
                    ... on Beer {
                        id
                        abv
                        ibu
                        srm
                        style {
                            title
                            id
                            abvMax
                            abvMin
                            fgMax
                            fgMin
                            ibuMax
                            ibuMin
                            hops
                            malt
                            ogMax
                            ogMin
                            srmMax
                            srmMin
                            yeast
                        }
                        title
                    }
                    ... on Kombucha {
                        id
                        title
                        __typename
                        color
                        probiotics
                        slug                       
                    }
                }
                vessel {
                    title
                    slug
                    iconSlug
                    shortTitle
                    material
                }
                depletionStats {
                    depletionPace
                    depletionRate
                    projectedKickDate
                    depletionEstimates {
                        date
                        fluidOz
                    }
                }
                cost
                tappedDate
                updatedDate
            }
            tappedKeg {
                actualKickDate
                cooler
                coupler
                currentFluidOz
                id
                isTemporary
                initialFluidOz
                kegStatus
                receivedDate
                lastUpdatedBy
                assignedPositionIndex
                product {
                    __typename
                    id
                    title
                    assets {
                        path
                        alt
                        type
                    }
                    brand {
                        id
                        title
                        displayTitle
                        assets {
                            __typename
                            id
                            alt
                            type
                            caption
                            ... on S3Asset {
                                path
                            }
                            ... on CloudinaryAsset {
                                cloudinaryId
                            }
                        }
                    }
                    ... on UnverifiedProduct {
                        brandName
                    }
                    ... on Beer {
                        id
                        abv
                        ibu
                        srm
                        style {
                            title
                            id
                            abvMax
                            abvMin
                            fgMax
                            fgMin
                            hops
                            ibuMax
                            ibuMin
                            malt
                            ogMax
                            ogMin
                            slug
                            srmMin
                            srmMax
                            yeast
                        }
                    }
                    ... on Kombucha {
                        id
                        title
                        color                       
                    }
                    ... on Coffee {
                        id
                        title
                        color
                    }
                    ... on Cider {
                        color
                    }
                }
                vessel {
                    title
                    slug
                    iconSlug
                    shortTitle
                    material
                }
                depletionStats {
                    depletionEstimates {
                        date
                        fluidOz
                    }
                    depletionPace
                    depletionRate
                    projectedKickDate
                }
                tappedDate
                updatedDate
            }
        }
    }
`
