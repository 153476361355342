"use client"

import { skipToken } from "@reduxjs/toolkit/query"
import type { BrewMenuSubscription } from "types"
import { useGetBrewMenuSubscriptionByIdQuery } from "../../api"
import { brewMenuSubscriptionToken } from "./utilities/brewMenuSubscriptionToken"

export interface useBrewmenuSubscriptionHook {
    isLoadingBrewmenuSubscription: boolean
    isErrorBrewmenuSubscription: boolean
    isFetchingBrewmenuSubscription: boolean
    isSuccessBrewmenuSubscription: boolean
    brewMenuSubscription: BrewMenuSubscription
}

export const useBrewmenuSubscription = (bmSubId: string): useBrewmenuSubscriptionHook => {
    const {
        data: brewMenuSubscription,
        isLoading: isLoadingBrewmenuSubscription,
        isError: isErrorBrewmenuSubscription,
        isFetching: isFetchingBrewmenuSubscription,
        isSuccess: isSuccessBrewmenuSubscription
    } = useGetBrewMenuSubscriptionByIdQuery(brewMenuSubscriptionToken(bmSubId) ?? skipToken)

    return {
        brewMenuSubscription,
        isLoadingBrewmenuSubscription,
        isErrorBrewmenuSubscription,
        isFetchingBrewmenuSubscription,
        isSuccessBrewmenuSubscription
    }
}
