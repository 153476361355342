"use client"
import { useEffect, useState } from "react"
import type { CompositeProduct } from "types"
import { type LimitedProductSearchInput, useLimitedProductSearchQuery } from "../../../api"

/**
 * Custom hook to perform a limited product search and paginated search on scroll
 * NOTE TO DEVS: Search has a ton of edge cases, tread lightly especially when modifying
 * the loadings states for pagination vs initial search.
 * @param {Object} params - The parameters for the search.
 * @param {string} [params.searchTerm=""] - The term to search for.
 * @param {number} [params.limit=25] - The maximum number of items to return per page.
 * @param {string} [params.brewType] - The type of brew to filter the search results.
 * @param {string} [params.order] - The order in which to sort the search results.
 * @param {string} [params.orderBy] - The field by which to order the search results.
 *
 * @returns {Object} - The search results and state.
 * @returns {CompositeProduct[]} items - List of products returned by the search.
 * @returns {boolean} isSearching - Indicates if search is currently in progress & no items returned (a new search).
 * @returns {boolean} isPaginating - Indicates if search is currently in progress & items already returned (a paginated search).
 * @returns {Function} fetchNext - Function to fetch the next set of search results on scroll.
 */
export const usePaginatedLimitedProductSearch = ({
    searchTerm = "",
    limit = 25,
    brewType,
    order,
    orderBy
}: LimitedProductSearchInput) => {
    const [allItems, setAllItems] = useState<CompositeProduct[]>([])
    const [nextChunkToken, setNextChunkToken] = useState<string>(undefined)

    const noSearchTerm: boolean = !searchTerm || searchTerm?.trim() === ""
    const shouldSkip: boolean = noSearchTerm && (nextChunkToken === "" || !nextChunkToken)

    const {
        data: searchResults,
        isLoading,
        isFetching
    } = useLimitedProductSearchQuery(
        {
            searchTerm,
            limit: limit || 25,
            brewType,
            order,
            orderBy,
            nextToken: nextChunkToken
        },
        {
            skip: shouldSkip
        }
    )

    const isQueryPending = isLoading || isFetching
    const { items, nextToken: newNextToken } = searchResults || {}

    // we can assume it was fired from a scroll event
    const isScrollEvent = Number(newNextToken) > limit
    // if prev token was greater than limit and new token is 0, stop paginating
    const stopPaginating = Number(nextChunkToken) > limit && newNextToken === "0"

    const fetchNext = () => {
        if (!stopPaginating) setNextChunkToken(newNextToken)
    }

    /** NOTE:
     * IF we are paginating, append new items to existing list
     * ELSE: replace existing list with new items
     */
    useEffect(() => {
        if (stopPaginating) return undefined

        if (isScrollEvent) {
            setAllItems((prev) => [...prev, ...items])
        } else {
            setAllItems(items || [])
        }
    }, [items])

    /**
     * NOTE: if the search term changes, reset the search results
     * let `searchResults.items` reset the list in useEffect above
     */
    useEffect(() => {
        setAllItems([])
        setNextChunkToken(undefined)
    }, [searchTerm])

    return {
        items: allItems,
        isSearching: isQueryPending && !allItems?.length,
        isPaginating: isQueryPending && !!allItems?.length,
        fetchNext
    }
}
