export const superAdminGetMessages = /* GraphQL */ `
query SuperAdminGetMessages($input: inputSuperAdminGetMessages) {
	superAdminGetMessages(input: $input) {
		items {
			barTitle
			code
			createdBy
			createdDate
			gatewayDeviceId
			gatewayId
			id
			isArchived
			isAutoClearable
			kegId
			lastConnection
			lastUpdatedBy
			message
			performancePlatformSubscription {
				id
				slug
				title
			}
			ppSubTitle
			sensorDeviceId
			source
			tapNumber
			tapSensorId
			tapSensorsInfo {
				barTitle
				gatewayDeviceId
				tapNumber
				tapSensorId
			}
			timeRead
			timeResolved
			timestamp
			type
			updatedDate
		}
		nextToken
		returnedCount
	}
}`
