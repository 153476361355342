"use client"

import { skipToken } from "@reduxjs/toolkit/query"
import type { PerformancePlatformSubscription } from "types"
import { useGetPPSubscriptionQuery } from "../api"

const token = (ppSubId: string) => {
    if (ppSubId && ppSubId.length > 0) return { ppSubId }

    return undefined
}

export interface UsePPSubscription {
    data: PerformancePlatformSubscription
    isFetching: boolean
}

export const usePPSubscription = (ppSubId: string): UsePPSubscription => {
    const {
        data,
        isFetching
    } = useGetPPSubscriptionQuery(token(ppSubId) ?? skipToken)

    return {
        data,
        isFetching
    }
}
