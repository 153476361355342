"use client"

import { useGetMyOrganizationGroupsQuery } from "../api/orgGroupsApi"

export const useOrgGroups = () => {
    const { data, isLoading, isFetching, isError, error, isSuccess } =
        useGetMyOrganizationGroupsQuery()

    return {
        groups: data ?? [],
        groupsAreLoading: isLoading,
        groupsError: error,
        groupsHasError: isError,
        groupsAreFetching: isFetching,
        isSuccess
    }
}
