"use client"
import type { GraphQLError } from "graphql"
import type { PerformancePlatformSubscription } from "types"

import type { SerializedError } from "@reduxjs/toolkit"

import { useGetPPSubscriptionQuery } from "../../api"
import { selectActivePpSubId } from "../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../store"

export interface UsePPSub {
    isFetchingPPSub: boolean
    ppSubId: string
    isSuccessPPSub: boolean
    isLoadingPPSub: boolean
    isPendingPPSub: boolean
    isErrorPPSub: boolean
    ppSubApiData: PerformancePlatformSubscription
    currentPPSubApiData: PerformancePlatformSubscription
    ppSubApiError: GraphQLError | SerializedError
    refetchPPSubData: () => unknown
}

export const usePPSub = (): UsePPSub => {
    const ppSubId = useAppSelector(selectActivePpSubId)

    const {
        data, // The latest returned result regardless of hook arg, if present
        currentData, // The latest returned result for the current hook arg, if present
        isError,
        error,
        isFetching,
        isLoading,
        isSuccess,
        status,
        originalArgs, // Arguments passed to the query
        requestId, // A string generated by RTK Query
        endpointName, // The name of the given endpoint for the query
        startedTimeStamp, // Timestamp for when the query was initiated
        fulfilledTimeStamp, // Timestamp for when the query was completed
        refetch
    } = useGetPPSubscriptionQuery({ ppSubId })

    return {
        ppSubApiData: data,
        currentPPSubApiData: currentData,
        ppSubId: data?.id || null,

        isSuccessPPSub: isSuccess,

        isErrorPPSub: isError,
        ppSubApiError: error,

        isFetchingPPSub: isFetching,
        isLoadingPPSub: isLoading,
        isPendingPPSub: !isError && !isSuccess && (isLoading || isFetching),

        refetchPPSubData: refetch
    }
}
