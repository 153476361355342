"use client"
import { useEffect } from "react"
import type { CompositeProduct } from "types"
import { useGetProductsByBrandIdQuery } from "../../api"
import { selectActiveBrandId } from "../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../store"

export interface UseProducts {
    isFetchingProducts: boolean
    products: CompositeProduct[]
    activeBrandId: string
    isSuccessProducts: boolean
    isLoadingProducts: boolean
    isProductsError: boolean
    refetchProducts: () => void
}

export const useProducts = (): UseProducts => {
    const activeBrandId = useAppSelector(selectActiveBrandId)
    const {
        data,
        isError: isProductsError,
        isFetching: isFetchingProducts,
        isLoading: isLoadingProducts,
        isSuccess: isSuccessProducts,
        refetch: refetchProducts
    } = useGetProductsByBrandIdQuery({ itemId: activeBrandId }, { skip: !activeBrandId })

    useEffect(() => {
        if (activeBrandId) {
            if (isSuccessProducts) refetchProducts()
        }
    }, [activeBrandId])

    return {
        refetchProducts,
        isSuccessProducts,
        isLoadingProducts,
        isProductsError,
        products: data?.products || [],
        isFetchingProducts,
        activeBrandId
    }
}
