import { useGetOrganizationPricingPresetByIdQuery } from "../../api"
import { useBLXUser } from "../../state"

export const usePricingPresetById = (itemId: string) => {
    const { orgId } = useBLXUser()

    const {
        data: pricingPresetById,
        isLoading: isLoadingPricingPresetById,
        isError: isErrorPricingPresetById,
        isFetching: isFetchingPricingPresetById,
        isSuccess: isSuccessPricingPresetById
    } = useGetOrganizationPricingPresetByIdQuery(
        { organizationId: orgId, pricingPresetId: itemId },
        { skip: !orgId || !itemId }
    )

    return {
        pricingPresetById,
        isLoadingPricingPresetById,
        isErrorPricingPresetById,
        isFetchingPricingPresetById,
        isSuccessPricingPresetById
    }
}
