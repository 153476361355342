const createVarianceReportMutation = /* GraphQL */ `
  mutation CreateReport($input: inputCreateReport!) {
    createReport(input: $input) {
      createdBy
      createdDate
      endDate
      errors
      id
      lastUpdatedBy
      report {
        # metadata { ...metadata }
        rows {
          label
        }
        # totals { ...totals }
      }
      s3PreProcessedUploadFields {
        fields
        url
      }
      s3RawUploadFields {
        fields
        url
      }
      startDate
      status
      title
      updatedDate
    }
  }
`

export default createVarianceReportMutation
