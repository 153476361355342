export const getDiscountPresetsByBMSubIdQuery = /* GraphQL */ `query GetDiscountPresetsByBMSubId($input: inputByBMSubId!) {
	getDiscountPresetsByBMSubId(input: $input){
		items {
			createdBy
			createdDate
			id
			lastUpdatedBy
			title
			updatedDate
		    dateTimeActive { 
                anyDate
                days
                endDate
                startDate
                hoursAvailable {
                    day
                    hours
                    isOpen
                } 
            }
			fixedAmount
			percent
			pourSizes
			productType
			type
		}
		nextToken
		returnedCount
	}
}`
