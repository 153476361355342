export const getProductByIdQuery = /* GraphQL */ `
    query getProductById($input: inputGetById!) {
        getProductById(input: $input) {
            __typename
            id
            abv
            color
            createdDate
            hintsOfFlavorNotes 
            isVerified
            isNewProduct
            isHiddenExternally
            primaryFlavorNotes
            releaseDate
            servingTemperature 
            servingVessels
            tastingExperience
            updatedDate
            description
            slug
            title
            foodPairingList {
                category
                title
            }
            assets {
                __typename
                id
                alt
                type
                width
                height
                bytes
                tags
                createdDate
                ... on CloudinaryAsset {
                    caption
                    cloudinaryId
                    category
                }
                ... on S3Asset {
                    path
                }
            }
            brand {
                id
                title
                displayTitle
                assets {
                    path
                    type
                    alt
                }
                address {
                    street
                    city
                    state
                }
            }
            ingredients {
                title
                type
            }
            traits {
                allergens
                availability
                nutrition {
                    calories
                    carbohydrates
                    cholesterol
                    dietaryFiber
                    fat
                    protein
                    saturatedFat
                    sodium
                    transFat
                    sugars
                }
                otherTraits
            }
            ... on Beer {
                originalGravity
                srm
                ibu
                styleAlias
                style {
                    id
                    title
                    category {
                        title
                    }
                    slug
                    label
                    primaryFlavorNotes
                }
            }
            ... on Kombucha {
                acidity
                appearance 
                body 
                brixCount 
                carbonation 
                kombuchaFamily 
                perceivedSweetness 
                probiotics 
                sourness
                teaCharacter 
            }
            ... on Cider {
                ciderFamily
                acidity
                perceivedSweetness
                body
                tannin
                brixCount
                carbonation
            }
        }
    }
`
