export const getBrewMenuSubscriptionByIdQuery = /* GraphQL */ `query GetBrewMenuSubscriptionById($input: inputGetBrewMenuSubscriptionById!) {
	getBrewMenuSubscriptionById(input: $input) {
	  lastUpdatedBy
	  createdBy
	  updatedDate
	  createdDate
	  id
	  hubSpotId
	  title
	  slug
	  subscriptionTier
	  isActive
	  brandId
	  ppSubId
	  locationIds
	  displays {
		id
		title
		displayType
		displayTitle
		slug
		updatedDate
		menu {
		  id
		  title
		  lastUpdatedBy
		  updatedDate
		}
	  }
	  organization{
		id
	  }
	  menus {
		id
		status
		title
		displays {
		  id
		  title
		}
		updatedDate
	  }
	  settings {
		address {
		  street
		  street2
		  city
		  state
		  country
		  postalCode
		  geo {
			lat
			lon
			title
		  }
		}
		contact {
		  phone
		  email
		}
	  }
	}
  }`
