export const getProductsByBrandId = /* GraphQL */ `
query GetBrandById($input: inputGetById!) {
  getBrandById(input: $input) {
      id
      slug
      title
      products {
        __typename
        id
        title
        slug
        abv
        color
        isNewProduct
        brand {
          featuredBrew {
            id
          }
        }
        traits {
            availability
        }
        assets {
          __typename
          id
          alt
          ... on S3Asset {
            path
          }
          ... on CloudinaryAsset {
              cloudinaryId
          }
        }
        ... on Beer {
          style {
            id
            title
            label
            description
            slug
          }
        }
        ... on Kombucha {
          kombuchaFamily
        }
        ... on Cider {
          ciderFamily
        }
      }
    }
  }
`
