export const getTotalTapThroughput = /* GraphQL */ `query GetTotalTapThroughput($input: inputGetTotalThroughput!) {
	getTotalTapThroughput(input: $input){
		throughput {
			avgCost
			costDepleted
			endDate
			performacePlatformId
			productCount
			startDate
			tapNumber
			tapsensorid
			throughput
		}
	}
}`
