export const plGetProductByIdQuery = /* GraphQL */ `
query GetProductById($input: inputGetById!) {
	getProductById(input: $input){
		__typename
		abv
		alcoholFree
		assets {
			alt
			# brand { ...brand }
			bytes
			caption
			category
			createdBy
			createdDate
			height
			id
			lastUpdatedBy
			# locations { ...locations }
			path
			# products { ...products }
			shared
			tags
			type
			updatedDate
			width
            ... on CloudinaryAsset {
                cloudinaryId
            }
		}
		brand {
			# address { ...address }
			# assets { ...assets }
			baId
			# contact { ...contact }
			createdBy
			createdDate
			description
			displayTitle
			# featuredBrew { ...featuredBrew }
			guid
			id
			lastUpdatedBy
			# locations { ...locations }
			# mediaLibrary { ...mediaLibrary }
			# organization { ...organization }
			# products { ...products }
			slug
			# socialMedia { ...socialMedia }
			subtitle
			title
			# traits { ...traits }
			updatedDate
			website
			yearEstablished
		}
		carbonation
		color
		createdBy
		createdDate
		description
		foodPairingList {
			category
			title
		}
		guid
		hintsOfFlavorNotes
		id
		ingredients {
			title
			type
		}
		isHiddenExternally
		isNewProduct
		isVerified
		lastUpdatedBy
		locationRelationships {
			available
			canned
			growler
			# location { ...location }
			onTap
			# product { ...product }
		}
		locationsAvailable {
			available
			canned
			growler
			# location { ...location }
			onTap
			# product { ...product }
		}
		primaryFlavorNotes
		releaseDate
		servingTemperature
		servingVessels
		slug
		style {
			abvMax
			abvMin
			alcoholFree
			blxGroup
			body
			carbonation
			# category { ...category }
			createdBy
			createdDate
			description
			fgMax
			fgMin
			# foodPairingList { ...foodPairingList }
			hintsOfFlavorNotes
			hops
			ibuMax
			ibuMin
			id
			kegFreshnessDuration
			label
			lastUpdatedBy
			malt
			ogMax
			ogMin
			perceivedBitterness
			perceivedProductBitterness
			perceivedSweetness
			primaryFlavorNotes
			productType
			servingTemperature
			servingVessels
			slug
			srmMax
			srmMin
			title
			updatedDate
			vintage
			yeast
		}
		tastingExperience
		title
		traits {
			allergens
			availability
			# nutrition { ...nutrition }
			otherTraits
		}
		updatedDate
		vintage
		... on Unverified {
        	ppSubId
		}
	}
}
`
