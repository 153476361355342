"use client"
import type { TapSensor } from "types"

import { useGetTapSensorsFromPPSubQuery } from "../../api/tapSensorsApi"
import { selectActivePpSubId } from "../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../store"

export interface IUseTapSensors {
    currentTapSensorsApiData?: TapSensor[]
    isFetchingtapSensors: boolean
    refetchTapSensors: () => void
    isSuccesstapSensors: boolean
    isLoadingtapSensors: boolean
    istapSensorsError: boolean
    isPendingTapSensors: boolean
    tapSensorsApiData: TapSensor[]
}

export const useTapSensors = (tapSensors?: TapSensor[]): IUseTapSensors => {
    const ppSubId = useAppSelector(selectActivePpSubId)

    const {
        data: tapSensorsApiData,
        currentData: currentTapSensorsApiData,
        isError: istapSensorsError,
        isFetching: isFetchingtapSensors,
        isLoading: isLoadingtapSensors,
        isSuccess: isSuccesstapSensors,
        refetch: refetchTapSensors
    } = useGetTapSensorsFromPPSubQuery({ ppSubId }, { skip: !ppSubId })

    return {
        currentTapSensorsApiData,
        isSuccesstapSensors,
        isLoadingtapSensors,
        isPendingTapSensors:
            !istapSensorsError &&
            !isSuccesstapSensors &&
            (isLoadingtapSensors || isFetchingtapSensors),
        istapSensorsError,
        tapSensorsApiData: tapSensors ?? tapSensorsApiData,
        isFetchingtapSensors,
        refetchTapSensors
    }
}
