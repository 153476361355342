import type { CreateStyle, InputGetAll, StyleConnection, UpdateStyle } from "types"
import { createStyleMutation } from "../mutations/private/styles/createStyleMutation"
import { updateStyleMutation } from "../mutations/private/styles/updateStyleMutation"
import { allStylesQuery } from "../queries/private/style/allStylesQuery-PP-inventory"
import { baseApi } from "./baseApi"

const styleApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStyles: builder.query<StyleConnection, InputGetAll>({
            providesTags: ["Styles"],
            query: (input) => ({
                query: allStylesQuery,
                args: input
            })
        }),
        createStyle: builder.mutation<void, CreateStyle>({
            invalidatesTags: ["Styles"],
            query: (input) => ({
                query: createStyleMutation,
                args: input
            })
        }),
        updateStyle: builder.mutation<void, UpdateStyle>({
            invalidatesTags: ["Styles"],
            query: (input) => ({
                query: updateStyleMutation,
                args: input
            })
        })
    })
})

const { useGetStylesQuery, useCreateStyleMutation, useUpdateStyleMutation } = styleApi

export { styleApi, useCreateStyleMutation, useGetStylesQuery, useUpdateStyleMutation }
