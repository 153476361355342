import Fuse from "fuse.js"
import type { CompositeProduct } from "types"
import { usePPSub } from "../../state"

export const usePriorityBrandsProductsSearch = (searchTerm: string) => {
    const { ppSubApiData, isLoadingPPSub } = usePPSub()
    const products: CompositeProduct[] =
        ppSubApiData?.priorityBrands.flatMap((brand) => brand.products) ?? []

    const fuse = new Fuse(products, {
        keys: ["title", "brand.title"],
        threshold: 0.25,
        useExtendedSearch: true
    })

    return {
        priorityBrandProducts: searchTerm
            ? fuse.search(searchTerm).map(({ item }) => item)
            : products,
        isLoading: isLoadingPPSub
    }
}
