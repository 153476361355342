import { toast } from "sonner"
import type {
    AddAssignedKeg,
    InputSuperAdminDeleteTapSensor,
    RearrangeTapSensorAssignedKegs,
    RemoveAssignedKeg,
    TapSensor
} from "types"
import type { RemoveAndReplaceTappedKeg } from "../../../../apps/ppp/src/types/schema/graphql"
import { addAssignedKegMutation } from "../mutations/private/inventory/addAssignedKegMutation"
import { deleteTapSensorMutation } from "../mutations/private/tapSensors/deleteTapSensorMutation"
import { rearrangeTapSensorAssignedKegsMutation } from "../mutations/private/tapSensors/rearrangeTapSensorAssignedKegsMutation"
import { removeAssignedKegMutation } from "../mutations/private/tapSensors/removeAssignedKegMutation"
import { baseApi } from "./baseApi"

/**
 * API for managing tap sensor mutations.
 */
/**
 * tapSensorMutationsApi provides a set of mutations for managing tap sensors and their assigned kegs.
 *
 * This API includes the following mutations:
 * - addKegAssignedKeg: Adds an assigned keg to a tap sensor.
 * - removeAssignedKeg: Removes an assigned keg from a tap sensor.
 * - changeTapOfAssignedKegs: Changes the tap of assigned kegs.
 * - removeAndReplaceTappedKeg: Removes and replaces a tapped keg.
 * - updateKegOrder: Updates the order of kegs.
 * - deleteTapSensor: Deletes a tap sensor.
 */
const tapSensorMutationsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        addKegAssignedKeg: builder.mutation<TapSensor, AddAssignedKeg>({
            invalidatesTags: [
                "TapSensors",
                { type: "TapSensors", id: "LIST" },
                "TapSensor",
                "UnassignedKegs",
                "TappedKegs"
            ],
            query: (input) => ({
                query: addAssignedKegMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                toast.success("Added Keg to TapSensor")
                return data
            }
        }),

        removeAssignedKeg: builder.mutation<TapSensor, RemoveAssignedKeg>({
            invalidatesTags: [
                "TapSensors",
                { type: "TapSensors", id: "LIST" },
                "TapSensor",
                "UnassignedKegs"
            ],
            query: (input) => ({
                query: removeAssignedKegMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                toast.success("Removed assigned keg")
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/updateKeg", error)
                toast.error("Error: Removing assigned keg")
            }
        }),
        changeTapOfAssignedKegs: builder.mutation<
            void,
            { kegId: string; ppSubId: string; currentTapSensorId: string; newTapSensorId: string }
        >({
            invalidatesTags: [
                "TapSensors",
                { type: "TapSensors", id: "LIST" },
                "TapSensor",
                "UnassignedKegs"
            ],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                const removeAssignedKegArgs: RemoveAssignedKeg = {
                    kegId: arg.kegId,
                    tapSensorId: arg.currentTapSensorId,
                    ppSubId: arg.ppSubId
                }
                await baseQuery({
                    query: removeAssignedKegMutation,
                    args: removeAssignedKegArgs
                })

                const addAssignedKegArgs: AddAssignedKeg = {
                    kegId: arg.kegId,
                    tapSensorId: arg.newTapSensorId,
                    ppSubId: arg.ppSubId
                }
                await baseQuery({
                    query: addAssignedKegMutation,
                    args: addAssignedKegArgs
                })
                return { data: undefined }
            }
        }),
        removeAndReplaceTappedKeg: builder.mutation<TapSensor, RemoveAndReplaceTappedKeg>({
            invalidatesTags: ["TapSensors", "TapSensor", "Kegs"],
            query: (input) => ({
                query: removeAssignedKegMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                toast.success("removeAndReplaceTappedKeg")
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/removeAndReplaceTappedKeg", error)
                toast.error("Error: removeAndReplaceTappedKeg")
            }
        }),
        updateKegOrder: builder.mutation<TapSensor, RearrangeTapSensorAssignedKegs>({
            invalidatesTags: ["TapSensors", "TapSensor"],
            query: (input) => ({
                query: rearrangeTapSensorAssignedKegsMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                toast.success("Rearrange assigned kegs")
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/updateKeg", error)
                toast.error("Error: Rearranging assigned keg")
            }
        }),
        deleteTapSensor: builder.mutation<void, InputSuperAdminDeleteTapSensor>({
            query: (input) => ({
                query: deleteTapSensorMutation,
                args: input
            })
        })
    })
})

const {
    useAddKegAssignedKegMutation,
    useRemoveAssignedKegMutation,
    useChangeTapOfAssignedKegsMutation,
    useUpdateKegOrderMutation,
    useRemoveAndReplaceTappedKegMutation,
    useDeleteTapSensorMutation
} = tapSensorMutationsApi

export {
    tapSensorMutationsApi,
    useAddKegAssignedKegMutation,
    useDeleteTapSensorMutation,
    useChangeTapOfAssignedKegsMutation,
    useRemoveAndReplaceTappedKegMutation,
    useRemoveAssignedKegMutation,
    useUpdateKegOrderMutation
}
