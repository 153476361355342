import { toastActions } from 'notifications/src/toasts'
import type {
    InputCreateReport,
    InputGetReport,
    InputGetReports,
    InputGetTotalThroughput,
    InputSuperAdminGetVarianceReports,
    InputUpdateReport,
    TrackedVarianceReport,
    TrackedVarianceReportConnection,
    VarianceReportTotals
} from "types"
import { baseApi } from '../../../api'
import { variance } from '../../../queries'
import createVarianceReportMutation from '../mutations/createReportMutation'
import { getVarianceReportTotalsQuery } from '../queries/getVarianceReportTotalsQuery'

const varianceReportsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getReports: builder.query<TrackedVarianceReportConnection, InputGetReports>({
            query: (input) => ({
                query: variance.getVarianceReportsQuery,
                args: input
            }),
            transformErrorResponse: () =>
                toastActions.addToast({
                    title: "Error",
                    description: "Failed to fetch reports",
                    variant: "error"
                })
        }),
        getVarianceReportTotals: builder.query<VarianceReportTotals, InputGetTotalThroughput>({
            query: (input) => ({
                query: getVarianceReportTotalsQuery,
                args: input
            }),
            transformErrorResponse: () => ({ variance: [] })
        }),
        getVarianceReport: builder.query<TrackedVarianceReport, InputGetReport>({
            query: (input) => ({
                query: variance.getVarianceReportQuery,
                args: input
            })
        }),
        getAllVarianceReportsSA: builder.query<
            TrackedVarianceReportConnection,
            InputSuperAdminGetVarianceReports
        >({
            query: (input) => ({
                query: variance.getSuperadminVarianceReportsQuery,
                args: input,
                invalidatesTags: ["VarianceReports"]
            })
        }),
        createReport: builder.mutation<TrackedVarianceReport, InputCreateReport>({
            invalidatesTags: ["VarianceReports"],
            query: (input) => ({
                query: createVarianceReportMutation,
                args: input
            })
        }),
        updateReport: builder.mutation<TrackedVarianceReport, InputUpdateReport>({
            invalidatesTags: ["VarianceReports"],
            query: (input) => ({
                query: createVarianceReportMutation,
                args: input
            })
        })
    })
})

const {
    useCreateReportMutation,
    useGetAllVarianceReportsSAQuery,
    useGetReportsQuery,
    useGetVarianceReportQuery,
    useGetVarianceReportTotalsQuery,
    useLazyGetAllVarianceReportsSAQuery,
    useLazyGetVarianceReportQuery: getVarianceReport,
} = varianceReportsApi

export {
    getVarianceReport,
    useCreateReportMutation,
    useGetAllVarianceReportsSAQuery,
    useGetReportsQuery,
    useGetVarianceReportQuery,
    useGetVarianceReportTotalsQuery,
    useLazyGetAllVarianceReportsSAQuery,
    varianceReportsApi,
}
