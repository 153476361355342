"use client"
import { type InputGetThroughputByProduct, TimeFrame, TimeZone } from "types"
import { useGetThroughputByProductQuery } from "../../../../api"
import getFormattedStartAndEndDateStringsFromADateRange from "../../../../utilities/getFormattedStartAndEndDateStringsFromADateRange"
import { usePPSub } from "../../../ppSubscription"

export const useThroughputByProductAndDate = (dateRange?: Date[]) => {
    const { ppSubId, ppSubApiData } = usePPSub()
    const skip = (dateRange && (!dateRange[0] || !dateRange[1])) || !ppSubId

    const { startDate, endDate } = getFormattedStartAndEndDateStringsFromADateRange({
        dateRange,
        timezone: ppSubApiData?.settings?.timeZone
    })

    const inputData: InputGetThroughputByProduct = {
        ppSubId,
        dateRange: {
            startDate,
            endDate
        },
        sortAscending: false,
        timeFrame: TimeFrame.Day,
        timeZone: ppSubApiData?.settings?.timeZone || TimeZone.UsEastern
    }

    const {
        data: throughputByProductHookData,
        isFetching: isFetchingThroughputByProduct,
        isLoading: isLoadingThroughputByProduct,
        isError: isErrorThroughputByProduct,
        refetch: refetchThroughputByProduct
    } = useGetThroughputByProductQuery(inputData, {
        skip
    })

    return {
        throughputByProductHookData,
        isFetchingThroughputByProduct,
        refetchThroughputByProduct,
        isLoadingThroughputByProduct,
        isErrorThroughputByProduct
    }
}
