import type {
    InputGetTotalThroughput,
    PerformancePlatformSubscription,
    TotalProductThroughput
} from "types"
import { useGetTotalProductThroughputQuery } from "../../../api"
import getFormattedStartAndEndDateStringsFromADateRange from "../../../utilities/getFormattedStartAndEndDateStringsFromADateRange"

export interface IUseThroughputByTotalProduct {
    totalProductThroughput?: TotalProductThroughput
    isFetchingTotalProductThroughput: boolean
    refetchTotalProductThroughput: () => void
    isLoadingTotalProductThroughput: boolean
    isErrorTotalProductThroughput: boolean
}

export const useThroughputByTotalProduct = ({
    dateRange,
    ppSubId,
    ppSubApiData
}: {
    dateRange: Date[]
    ppSubId: string
    ppSubApiData: PerformancePlatformSubscription
}): IUseThroughputByTotalProduct => {
    const { startDate, endDate } = getFormattedStartAndEndDateStringsFromADateRange({
        dateRange,
        timezone: ppSubApiData?.settings?.timeZone
    })

    const inputData: InputGetTotalThroughput = {
        ppSubId,
        dateRange: {
            startDate,
            endDate
        }
    }

    const {
        data: totalProductThroughput,
        isFetching: isFetchingTotalProductThroughput,
        isLoading: isLoadingTotalProductThroughput,
        isError: isErrorTotalProductThroughput,
        refetch: refetchTotalProductThroughput
    } = useGetTotalProductThroughputQuery(inputData, {
        skip: dateRange && (!dateRange[0] || !dateRange[1])
    })

    return {
        totalProductThroughput,
        isFetchingTotalProductThroughput,
        isLoadingTotalProductThroughput,
        isErrorTotalProductThroughput,
        refetchTotalProductThroughput
    }
}
