import { toast } from "sonner"
import {
    type CreateKegs,
    type DeleteKeg,
    type InputGetByPpSubId,
    type InputGetKegsByStatus,
    type InputGetPreviouslyReceivedKegs,
    type InputPromoteAssignedKegs,
    type InputSuperAdminPromoteAssignedKegs,
    type Keg,
    type KegConnection,
    KegStatus,
    type RemoveTappedKeg,
    type SetTappedKeg,
    type TapSensor,
    type UpdateKeg
} from "types"
import { promoteAssignedKegsMutation, superAdminPromoteAssignedKegsMutation } from "utilities"
import { createKegsMutation } from "../mutations/private/inventory/createKegsMutation"
import { deleteKegMutation } from "../mutations/private/inventory/deleteKegMutation"
import { removeTappedKegMutation } from "../mutations/private/inventory/removeTappedKegMutation"
import { setTappedKegMutation } from "../mutations/private/inventory/setTappedKegMutation"
import { updateKegMutation } from "../mutations/private/inventory/updateKegMutation"
import { getKegById, getKegsByStatusQuery } from "../queries/private/inventory"
import { getPreviouslyRecievedKegs } from "../queries/private/inventory/getPreviouslyRecievedKegs"
import { baseApi } from "./baseApi"

/**
 * The `inventoryApi` object is an extension of the base API that provides various endpoints
 * for managing and querying kegs in the inventory. It includes endpoints for fetching kegs
 * by different statuses, creating, updating, and deleting kegs, as well as promoting and
 * setting tapped kegs. Each endpoint is defined using the `builder.query` or `builder.mutation`
 * methods, and includes appropriate tags for cache invalidation and error handling with toast notifications.
 *
 * Endpoints:
 * - `getInventoryKegs`: Fetches all kegs with pagination support.
 * - `getKegsByStatus`: Fetches kegs by a specific status with pagination support.
 * - `getArchivedKegs`: Fetches kegs with the status `Depleted`.
 * - `getAssignedKegs`: Fetches kegs with the status `Assigned`.
 * - `getUnassignedKegs`: Fetches kegs with the status `Unassigned`.
 * - `getTappedKegs`: Fetches kegs with the status `Tapped`.
 * - `getKegById`: Fetches a keg by its ID.
 * - `deleteKeg`: Deletes a keg.
 * - `createKegs`: Creates new kegs.
 * - `removeTappedKeg`: Removes a tapped keg.
 * - `superAdminPromoteAssignedKeg`: Promotes an assigned keg with super admin privileges.
 * - `promoteAssignedKeg`: Promotes an assigned keg.
 * - `setTappedKeg`: Sets a keg as tapped.
 * - `updateKeg`: Updates a keg's information.
 * - `getPreviouslyReceivedKegs`: Fetches previously received kegs.
 */
const inventoryApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getInventoryKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["Kegs"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                let nextToken = undefined
                let results: Keg[] = []
                do {
                    const response = await baseQuery({
                        query: getKegsByStatusQuery,
                        args: {
                            limit: 750,
                            ...arg,
                            nextToken
                        }
                    })
                    results = [...results, ...response.data.items]
                    nextToken = response?.data?.nextToken
                } while (nextToken)
                return { data: results }
            }
        }),
        getKegsByStatus: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["Kegs"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                let nextToken = undefined
                let results: Keg[] = []
                do {
                    const response = await baseQuery({
                        query: getKegsByStatusQuery,
                        args: {
                            ...arg,
                            nextToken
                        }
                    })
                    results = [...results, ...response.data.items]
                    nextToken = response?.data?.nextToken
                } while (nextToken)
                return { data: results }
            }
        }),
        getArchivedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["ArchivedKegs"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                let nextToken = undefined
                let results: Keg[] = []
                do {
                    const response = await baseQuery({
                        query: getKegsByStatusQuery,
                        args: {
                            ...arg,
                            kegStatus: KegStatus.Depleted,
                            nextToken
                        }
                    })
                    results = [...results, ...response.data.items]
                    nextToken = response?.data?.nextToken
                } while (nextToken)
                return { data: results }
            }
        }),
        getAssignedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["AssignedKegs"],
            query: (input) => ({
                query: getKegsByStatusQuery,
                args: { ...input, kegStatus: KegStatus.Assigned }
            }),
            transformResponse: (data: KegConnection): Keg[] => {
                return data.items || []
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch assigned kegs")
            }
        }),
        getUnassignedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["UnassignedKegs"],
            query: (input) => ({
                query: getKegsByStatusQuery,
                args: { ...input, kegStatus: KegStatus.Unassigned }
            }),
            transformResponse: (data: KegConnection): Keg[] => {
                return data.items || []
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch unassigned kegs")
            }
        }),
        getTappedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["TappedKegs"],
            query: (input) => ({
                query: getKegsByStatusQuery,
                args: { ...input, kegStatus: KegStatus.Tapped }
            }),
            transformResponse: (data: KegConnection): Keg[] => {
                return data.items || []
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch tapped kegs")
            }
        }),
        getKegById: builder.query<Keg, InputGetByPpSubId>({
            providesTags: ["KegById"],
            query: (input) => ({
                query: getKegById,
                args: { limit: 1, ...input }
            }),
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch keg by ID")
            }
        }),
        deleteKeg: builder.mutation<Keg, DeleteKeg>({
            query: (input) => ({
                query: deleteKegMutation,
                args: input
            }),
            invalidatesTags: [
                "Kegs",
                "AssignedKegs",
                "KegById",
                "TappedKegs",
                "OnDeckKegs",
                "UnassignedKegs"
            ],
            transformResponse: (data: Keg): Keg => {
                toast.success("Your keg was deleted")
                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to delete keg")
            }
        }),
        createKegs: builder.mutation<Keg[], CreateKegs>({
            invalidatesTags: ["Kegs", "AssignedKegs", "TappedKegs", "UnassignedKegs"],
            query: (input) => ({
                query: createKegsMutation,
                args: input
            }),
            transformErrorResponse: (error) => {
                toast.error("Failed to create kegs")
            }
        }),
        removeTappedKeg: builder.mutation<TapSensor, RemoveTappedKeg>({
            query: (input) => ({
                query: removeTappedKegMutation,
                args: input
            }),
            invalidatesTags: [
                "TapSensors",
                "TappedKegs",
                "UnassignedKegs",
                "TappedKegs",
                "AssignedKegs"
            ],
            transformErrorResponse: (error) => {
                toast.error("Failed to remove tapped keg")
            }
        }),
        superAdminPromoteAssignedKeg: builder.mutation<
            TapSensor,
            InputSuperAdminPromoteAssignedKegs
        >({
            query: (input) => ({
                query: superAdminPromoteAssignedKegsMutation,
                args: input
            }),
            invalidatesTags: ["Kegs", "TapSensors", "UnassignedKegs", "AssignedKegs"],
            transformErrorResponse: (error) => {
                toast.error("Failed to promote assigned keg")
            }
        }),
        promoteAssignedKeg: builder.mutation<TapSensor, InputPromoteAssignedKegs>({
            query: (input) => ({
                query: promoteAssignedKegsMutation,
                args: input
            }),
            invalidatesTags: [
                "Kegs",
                "KegById",
                "TapSensors",
                "TapSensor",
                "UnassignedKegs",
                "AssignedKegs",
                "TappedKegs"
            ],
            transformErrorResponse: (error) => {
                toast.error("Failed to promote assigned keg")
            }
        }),
        setTappedKeg: builder.mutation<TapSensor, SetTappedKeg>({
            query: (input) => ({
                query: setTappedKegMutation,
                args: input
            }),
            invalidatesTags: ["Kegs", "TapSensors", "UnassignedKegs", "AssignedKegs"],
            transformErrorResponse: (error) => {
                toast.error("Failed to set tapped keg")
            }
        }),
        updateKeg: builder.mutation<Keg, UpdateKeg>({
            query: (input) => ({
                query: updateKegMutation,
                args: input
            }),
            invalidatesTags: [
                "TapSensors",
                "TapSensor",
                "Kegs",
                "AssignedKegs",
                "KegById",
                "TappedKegs",
                "OnDeckKegs",
                "UnassignedKegs",
                "ArchivedKegs",
                "PPSub"
            ],
            transformResponse: (data: Keg): Keg => {
                toast.success("Updated keg", { duration: 6000 })
                return data as Keg
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to update keg")
            }
        }),
        getPreviouslyReceivedKegs: builder.query<KegConnection, InputGetPreviouslyReceivedKegs>({
            providesTags: ["Kegs", "AssignedKegs", "UnassignedKegs"],
            query: (input) => ({
                query: getPreviouslyRecievedKegs,
                args: input
            }),
            transformResponse: (data: KegConnection): KegConnection => {
                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch previously received kegs")
            }
        })
    })
})

const {
    useUpdateKegMutation,
    useGetAssignedKegsQuery,
    useGetInventoryKegsQuery,
    useGetKegByIdQuery,
    useLazyGetKegByIdQuery,
    useGetArchivedKegsQuery,
    useGetTappedKegsQuery,
    useGetUnassignedKegsQuery,
    useDeleteKegMutation,
    useCreateKegsMutation,
    useRemoveTappedKegMutation,
    useSetTappedKegMutation,
    useSuperAdminPromoteAssignedKegMutation,
    useGetPreviouslyReceivedKegsQuery,
    useGetKegsByStatusQuery
} = inventoryApi

export {
    inventoryApi,
    useCreateKegsMutation,
    useDeleteKegMutation,
    useGetArchivedKegsQuery,
    useGetAssignedKegsQuery,
    useGetInventoryKegsQuery,
    useGetKegByIdQuery,
    useGetPreviouslyReceivedKegsQuery,
    useGetTappedKegsQuery,
    useGetUnassignedKegsQuery,
    useLazyGetKegByIdQuery,
    useRemoveTappedKegMutation,
    useUpdateKegMutation,
    useGetKegsByStatusQuery
}
