import { ProductFields, ProductStatusFilter, ProductTypeFilter, SortDirection } from "types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

type Data = {
    sortingBy: ProductFields | null
    sortDir: SortDirection
    searchTerm: string
    productTypeFilter: ProductTypeFilter
    page: number
    statusFilter: ProductStatusFilter
}

type Actions = {
    setSortingBy: (field: ProductFields | null) => void
    setSortDir: (dir: SortDirection) => void
    resetSorting: () => void
    setSearchTerm: (term: string) => void
    setProductTypeFilter: (filter: ProductTypeFilter) => void
    pageUp: () => void
    pageDown: () => void
    setStatusFilter: (filter: ProductStatusFilter) => void
}

const defaultData: Data = {
    sortingBy: ProductFields.Title,
    sortDir: SortDirection.Desc,
    searchTerm: "",
    productTypeFilter: ProductTypeFilter.All,
    page: 1,
    statusFilter: ProductStatusFilter.All
}

/**
 * Store to hold the user's search & pagination state.
 * This is NOT intended to hold any api data
 */
export const useProductSearchStore = create<Data & Actions>()(
    devtools((set, get) => ({
        ...defaultData,
        setSortingBy: (field) => set({ sortingBy: field }),
        setSortDir: (dir) => set({ sortDir: dir }),
        resetSorting: () => set({ sortingBy: defaultData.sortingBy, sortDir: defaultData.sortDir }),
        setSearchTerm: (term) => set({ searchTerm: term, page: 1, sortingBy: null }),
        setProductTypeFilter: (filter) => set({ productTypeFilter: filter }),
        pageUp: () => set({ page: get().page + 1 }),
        pageDown: () => set({ page: get().page - 1 }),
        setStatusFilter: (filter) => set({ statusFilter: filter })
    }))
)
