"use client"
import { DateTime } from "luxon"
import { type InputGetThroughputByKegId, type TimeFrame, TimeZone } from "types"
import { TimeMapping, getBeginingOfDayTimestamp, getEndOfDayTimestamp } from "utilities"
import { useGetThroughputByKegIdQuery } from "../../../api"
import { ppSubState } from "../../ppSubscription"

export interface IUseThroughputByKegIdProps {
    kegId: string | undefined
    timeFrame: TimeFrame
}

export const useThroughputByKegId = ({ kegId, timeFrame }: IUseThroughputByKegIdProps) => {
    const userTimeZone = ppSubState.getState()?.ppSub?.settings?.timeZone
    const sevenDaysAgo: string = DateTime.fromJSDate(new Date())
        .setZone(TimeMapping[userTimeZone] || TimeMapping["US___East__Indiana"])
        .minus({ days: 7 })
        .toString()

    const today: string = DateTime.fromJSDate(new Date())
        .setZone(TimeMapping[userTimeZone] || TimeMapping["US___East__Indiana"])
        .toString()
    const startDate = getBeginingOfDayTimestamp(sevenDaysAgo)
    const endDate = getEndOfDayTimestamp(today)
    const inputData: InputGetThroughputByKegId = {
        ppSubId: ppSubState.getState()?.ppSubId,
        kegId,
        dateRange: {
            startDate,
            endDate
        },
        sortAscending: false,
        timeFrame,
        timeZone: userTimeZone || TimeZone.UsCentral
    }

    const { data, isFetching, isLoading, refetch, isError, error } = useGetThroughputByKegIdQuery(
        inputData,
        { skip: !kegId }
    )
    return {
        data,
        isFetching,
        isLoading,
        refetch,
        isError,
        error
    }
}
