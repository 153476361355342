import type {
    BrewMenuDisplay,
    InputCreateBrewMenuDisplay,
    InputDeleteBrewMenuDisplay,
    InputUpdateBrewMenuDisplay
} from "types"
import {
    useCreateBrewMenuDisplayMutation,
    useDeleteBrewMenuDisplayMutation,
    useUpdateBrewMenuDisplayMutation
} from "../../../api/brewMenu/brewMenuDisplayApi"

export const useBrewMenuDisplayMutations = (bmSubId: string) => {
    const [createDisplayMutation] = useCreateBrewMenuDisplayMutation()
    const [deleteDisplayMutation] = useDeleteBrewMenuDisplayMutation()
    const [updateDisplayMutation] = useUpdateBrewMenuDisplayMutation()

    const createDisplay = async (input: {
        // TODO: Is this optional
        brewMenuId?: InputCreateBrewMenuDisplay["brewMenuId"]
        // TODO: Is this optional
        displayTitle?: InputCreateBrewMenuDisplay["displayTitle"]
        displayType: InputCreateBrewMenuDisplay["displayType"]
        // TODO: Is this optional
        settings?: InputCreateBrewMenuDisplay["settings"]
        // TODO: Is this optional
        slug?: InputCreateBrewMenuDisplay["slug"]
        status: InputCreateBrewMenuDisplay["status"]
        title: InputCreateBrewMenuDisplay["title"]
    }): Promise<BrewMenuDisplay> => {
        // @ts-ignore
        const { data } = await createDisplayMutation({
            bmSubId,
            ...input
        })

        if (data) return data
    }

    const deleteDisplay = async (
        brewMenuDisplayId: InputDeleteBrewMenuDisplay["brewMenuDisplayId"]
    ) => {
        await deleteDisplayMutation({
            bmSubId,
            brewMenuDisplayId
        })
    }

    const updateDisplay = async (input: {
        brewMenuDisplayId: InputUpdateBrewMenuDisplay["brewMenuDisplayId"]
        // TODO: Is this optional
        brewMenuId?: InputUpdateBrewMenuDisplay["brewMenuId"]
        // TODO: Is this optional
        displayTitle?: InputUpdateBrewMenuDisplay["displayTitle"]
        displayType: InputUpdateBrewMenuDisplay["displayType"]
        // TODO: Is this optional
        settings?: InputUpdateBrewMenuDisplay["settings"]
        // TODO: Is this optional
        slug?: InputUpdateBrewMenuDisplay["slug"]
        status: InputUpdateBrewMenuDisplay["status"]
        // TODO: Is this optional
        title?: InputUpdateBrewMenuDisplay["title"]
        cloudinaryAssetId?: InputUpdateBrewMenuDisplay["cloudinaryAssetId"]
        backgroundWatermarkAssetId?: InputUpdateBrewMenuDisplay["backgroundWatermarkAssetId"]
        promotionalImageAssets?: InputUpdateBrewMenuDisplay["promotionalImageAssets"]
    }) => {
        const res = await updateDisplayMutation({
            bmSubId,
            ...input
        })
        if (res) return res
    }

    return {
        createDisplay,
        deleteDisplay,
        updateDisplay
    }
}
