import { createSelector } from "@reduxjs/toolkit"
import type { RootState } from "../store"

const throughputTableFilterState = (state: RootState) => state.activeThroughputFilter

const selectActiveThroughputTableFilter = createSelector(
    throughputTableFilterState,
    ({ activeThroughputTableFilter }) => activeThroughputTableFilter
)

export { selectActiveThroughputTableFilter }
