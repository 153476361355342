export const getVarianceReportTotalsQuery = /* GraphQL */ `query GetVarianceReport($input: inputGetTotalThroughput!) {
  getVarianceReport(input: $input) {
    metadata {
      totalYield
      total_depleted
      total_rev_variance
      total_sold
      total_variance
    }
    variance {
      blx_product
      pos_product_matched
      rev_variance
      total_depleted
      total_sold
      variance
    }
  }
}`
