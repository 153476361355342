export const getMediaLibraryQuery = /* GraphQL */ `
query GetMediaLibrary($input: inputGetMediaLibrary!) {
    getMediaLibrary(input: $input) {
      items {
        __typename
        id
        alt
        type
        updatedDate
        caption
        category
        createdDate
        createdBy
        bytes 
        width
        height
        tags
        ... on S3Asset {
          path
          filename
        }
        ... on CloudinaryAsset {
            cloudinaryId
        }
        brand {
            id
            title
            slug
        }
        products {
            id
            title
            slug
        }
        locations {
            id
            title
            slug
        }
      }
      returnedCount
    }
}
`
