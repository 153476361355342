"use client"

import type { GraphQLError } from "graphql"
import type { Organization } from "types"

import type { SerializedError } from "@reduxjs/toolkit"
import { skipToken } from "@reduxjs/toolkit/query"

import { useGetOrganizationByIdQuery } from "../../api"
import { useAppSelector } from "../store"
import { sessionUser } from "../user"

export interface UseOrganization {
    isFetchingOrganization: boolean
    organization: Organization
    organizationId: string
    organizationError: GraphQLError | SerializedError
    refetchOrganization: () => void
    isSuccessOrganization: boolean
    isLoadingOrganization: boolean
    isOrganizationError: boolean
}

const token = (organizationId: string): { organizationId: string } | undefined => {
    if (organizationId && organizationId.length > 0) return { organizationId }

    return undefined
}

export const useOrganization = (): UseOrganization => {
    const orgId = useAppSelector(sessionUser)?.organization?.organizationId

    const {
        data: organization,
        isError: isOrganizationError,
        isFetching: isFetchingOrganization,
        isLoading: isLoadingOrganization,
        isSuccess: isSuccessOrganization,
        refetch: refetchOrganization,
        error: organizationError
    } = useGetOrganizationByIdQuery(token(orgId) ?? skipToken)

    return {
        refetchOrganization,
        isSuccessOrganization,
        isLoadingOrganization,
        isOrganizationError,
        organizationError,
        isFetchingOrganization,
        organization,
        organizationId: orgId
    }
}
