const createAccountRequestMutation = /* GraphQL */ `
mutation CreateAccountRequest($input: inputCreateAccountRequest!) {
    createAccountRequest(input: $input) {
      additionalData
      approvedDate
      claimedBrand
      contact {
        email
        phone
      }
      createdBy
      createdDate
      familyName
      givenName
      id
      lastUpdatedBy
      newBrandAddress {
        city
        country
        geo {
          lat
          lon
          title
        }
        postalCode
        state
        street
        street2
      }
      newBrandTitle
      notes
      requestedRole
      status
      updatedDate
      userId
    }
  }
`

export default createAccountRequestMutation
