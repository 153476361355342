export const allStyles = /* GraphQL */ `
    query GetStyles ($input: inputGetAll) {
        getStyles (input: $input) {
        items {
            id
            title
            category {
            	title
            }
			primaryFlavorNotes
        }
        nextToken
    }
}
`
