import type {
    BrewMenuCustomProduct,
    CreateProduct,
    InputCreateCustomProduct,
    InputUpdateCustomProduct,
    Product
} from "types"
import { datadogLocalErrorLog } from "../../datadog/datadogErrorLogger"
import { createBrewMenuCustomProduct } from "../../mutations/private/brewmenu/createBrewMenuCustomProduct"
import { createBrewMenuUnverifiedProductMutation } from "../../mutations/private/brewmenu/createBrewMenuUnverifiedProductMutation"
import { updateBrewMenuCustomProduct } from "../../mutations/private/brewmenu/updateBrewMenuCustomProduct"
import { baseApi } from "../baseApi"

const brewMenuProductApiLog = datadogLocalErrorLog("brewMenuProductApi")

const brewMenuProductApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenuCustomProduct
        createBrewMenuCustomProduct: builder.mutation<
            BrewMenuCustomProduct,
            InputCreateCustomProduct
        >({
            query: (input) => ({
                query: createBrewMenuCustomProduct,
                args: input
            }),
            transformResponse: (data: BrewMenuCustomProduct): BrewMenuCustomProduct => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuProductApiLog("createBrewMenuCustomProduct")
        }),
        // https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createProduct
        createBrewMenuUnverifiedProduct: builder.mutation<Product, CreateProduct>({
            query: (input) => ({
                query: createBrewMenuUnverifiedProductMutation,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions

                return data
            },
            transformErrorResponse: brewMenuProductApiLog("createBrewMenuUnverifiedProduct")
        }),
        // https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenuCustomProduct
        updateBrewMenuCustomProduct: builder.mutation<
            BrewMenuCustomProduct,
            InputUpdateCustomProduct
        >({
            query: (input) => ({
                query: updateBrewMenuCustomProduct,
                args: input
            }),
            transformResponse: (data: BrewMenuCustomProduct): BrewMenuCustomProduct => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuProductApiLog("updateBrewMenuCustomProduct")
        })
    })
})

const {
    useCreateBrewMenuCustomProductMutation,
    useCreateBrewMenuUnverifiedProductMutation,
    useUpdateBrewMenuCustomProductMutation
} = brewMenuProductApi

export {
    brewMenuProductApi,
    // https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenuCustomProduct
    useCreateBrewMenuCustomProductMutation,
    // https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createProduct
    useCreateBrewMenuUnverifiedProductMutation,
    // https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenuCustomProduct
    useUpdateBrewMenuCustomProductMutation
}
