export const rearrangeTapSensorAssignedKegsMutation = /* GraphQL */ `
mutation rearrangeTapSensorAssignedKegs($input: rearrangeTapSensorAssignedKegs!) {
	rearrangeTapSensorAssignedKegs(input: $input) {
		id
		tapNumber
		assignedKegs {
			id
			product {
				title
			}

		}
	}
}`
