export const getBrandByIdQuery = /* GraphQL */ `
query GetBrandById($input: inputGetById!) {
  getBrandById(input: $input) {
      __typename
      id
      slug
      title
      displayTitle
      description
      address {
        street
        street2
        city
        state
        country
        postalCode
      }
	  traits {
		  brandTypes
		  brewTypes
		  status
		  isIndependent
		  isContract
	  }
      contact {
        phone
        email
      }
      website
      socialMedia {
        platform
        handle
      }
      assets {
        __typename
        id
        alt
        type
        updatedDate
        createdDate
        createdBy
        bytes 
        width
        height
        tags
        ... on S3Asset {
          path
          filename
        }
        ... on CloudinaryAsset {
            cloudinaryId
            caption
            category
        }
	  }
      yearEstablished
    }
  }
`
