export const allStylesQuery = /* GraphQL */ `
query GetStyles($input: inputGetAll) {
	getStyles(input: $input){
        items {
			updatedDate
			title
			id
			createdDate
			abvMax
			abvMin
			alcoholFree
			blxGroup
			body
			carbonation
      	foodPairingList {
				title
			}
            category {
				title
			}
			createdBy
			description
			fgMax
			fgMin
			hintsOfFlavorNotes
			hops
			ibuMax
			ibuMin
			kegFreshnessDuration
			label
			lastUpdatedBy
			malt
			ogMax
			ogMin
			perceivedBitterness
			perceivedProductBitterness
			perceivedSweetness
			primaryFlavorNotes
			productType
			servingTemperature
			servingVessels
			slug
			srmMax
			srmMin
			vintage
			yeast
		}
		nextToken
		returnedCount
		totalResults
	}
}
`
