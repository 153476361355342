import { gql } from 'graphql-request';

export const superAdminGetOrganizationsQuery = gql`
query SuperAdminGetOrganizations($input: inputSuperAdminGetOrganizations) {
	superAdminGetOrganizations(input: $input){
		items {
			contact {
				email
				phone
			}
			brands {
				__typename
				id
				title
				displayTitle
				slug
			}
			brewMenuSubscriptions {
				__typename
				id
				title
				brandId
				isActive
				ppSubId
			}
			ppSubscriptions {
				__typename
				id
				title
				address {
					street
					city
					state
					postalCode
					country
				}
			}
			createdBy
			createdDate
			externalId
			hubspotId
			id
			lastUpdatedBy
			owner {
				id
				givenName
				familyName
				brewlogixSuperadmin
				contact {
				email
				phone
				}
				organization {
				organizationId
				role
				}
			}
			billing {
				contact {
					phone
					email
				}
			}
			slug
			status
			title
			updatedDate
			address {
				city
				state
			}
			users {
				id
				givenName
				familyName
				brewlogixSuperadmin
				contact {
					email
					phone
				}
				organization {
					organizationId
					role
				}
			}
		}
		nextToken
		returnedCount
	}
}
`
