"use client"
import type { KegConnection } from "types"
import { useGetPreviouslyReceivedKegsQuery } from "../../api"

interface UsePreviouslyReceivedKegsProps {
    previouslyReceivedKegConnection: KegConnection
    isErrorPreviouslyReceivedKegs: boolean
    isFetchingPreviouslyReceivedKegs: boolean
    isLoadingPreviouslyReceivedKegs: boolean
    isSuccessPreviouslyReceivedKegs: boolean
}

export const usePreviouslyReceivedKegs = (
    ppSubId: string,
    timeFrameDays = 365
): UsePreviouslyReceivedKegsProps => {
    const { data, isError, isFetching, isLoading, isSuccess } = useGetPreviouslyReceivedKegsQuery(
        { ppSubId, receivedInLastXDays: timeFrameDays },
        { skip: !ppSubId }
    )

    return {
        previouslyReceivedKegConnection: data,
        isErrorPreviouslyReceivedKegs: isError,
        isFetchingPreviouslyReceivedKegs: isFetching,
        isLoadingPreviouslyReceivedKegs: isLoading,
        isSuccessPreviouslyReceivedKegs: isSuccess
    }
}
