export const getMyOrganizationGroupsQuery = /* GraphQL */ `
query GetMyOrganizationGroups($input: inputEmpty) {
	getMyOrganizationGroups(input: $input){
    __typename
    bmSubId
    brandId
    cloudinaryAssetId
    id
    ppSubId
    slug
    title
  }
}`
