export const updateDiscountPresetMutation = /* GraphQL */ `mutation UpdateDiscountPreset($input: inputUpdateDiscountPreset!) {
	updateDiscountPreset(input: $input){
		updatedDate
		title
		lastUpdatedBy
		id
		createdDate
		createdBy
		dateTimeActive {
			anyDate
			days
			endDate
			# hoursAvailable { ...hoursAvailable }
			startDate
		}
		fixedAmount
		percent
		pourSizes
		type
	}
}`
