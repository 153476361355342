import { createSlice } from "@reduxjs/toolkit"

interface ApplicationState {
    orgSelectorIsOpen: boolean
}

const initialState: ApplicationState = {
    orgSelectorIsOpen: false
}

const applicationStateSlice = createSlice({
    name: "application-state",
    initialState,
    reducers: {
        closeOrgGroupSelector: (state) => {
            state.orgSelectorIsOpen = false
        },
        openOrgGroupSelector: (state) => {
            state.orgSelectorIsOpen = true
        },
        toggleOrgGroupModal: (state) => {
            state.orgSelectorIsOpen = !state.orgSelectorIsOpen
        }
    },
    selectors: {
        selectOrgGroupSelectorModal: ({ orgSelectorIsOpen }): boolean => orgSelectorIsOpen
    }
})

export const { closeOrgGroupSelector, openOrgGroupSelector, toggleOrgGroupModal } =
    applicationStateSlice.actions

export const { selectOrgGroupSelectorModal } = applicationStateSlice.selectors

export default applicationStateSlice
