import { getBasicOrganizationByIdQuery } from "./getBasicOrganizationByIdQuery"
import { getOrganizationAssetByIdQuery } from "./getOrganizationAssetByIdQuery"
import { getOrganizationByIdQuery } from "./getOrganizationByIdQuery"
import { getOrganizationPricingPresetById } from "./getOrganizationPricingPresetById"
import { getOrganizationPricingPresets } from "./getOrganizationPricingPresets"
import { superAdminGetOrganizationsQuery } from "./superAdminGetOrganizationsQuery"

export {
    getBasicOrganizationByIdQuery,
    getOrganizationAssetByIdQuery,
    getOrganizationByIdQuery,
    getOrganizationPricingPresetById,
    getOrganizationPricingPresets,
    superAdminGetOrganizationsQuery
}
