export const getTotalProductThroughput = /* GraphQL */ `query GetTotalProductThroughput($input: inputGetTotalThroughput!) {
	getTotalProductThroughput(input: $input){
		throughput {
			abv
			avgCost
			brandTitle
			costDepleted
			endDate
			performacePlatformId
			productId
			productTitle
			productType
			startDate
			styleLabel
			styleTitle
			throughput
		}
	}
}`
