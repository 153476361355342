import type {
    InputGetById,
    InputSuperAdminGetUsers,
    InputSuperAdminUpdateUser,
    UpdateUser,
    User,
    UserConnection
} from "types"
import type { InputCreateUserReference } from "types/src/blx-gql-schema-public/graphql"
import { useToasts } from "../../../notifications/src/toasts"
import { superAdminUpdateUserMutation } from "../mutations/private/user/superAdminUpdateUserMutation"
import { updateUserMutation } from "../mutations/private/user/updateUserMutation"
import { getAuthenticatedUser } from "../queries/private"
import { getAllUsersQuery } from "../queries/private/users/getAllUsersQuery"
import { superAdminGetUserById } from "../queries/private/users/superAdminGetUserById"
import { superAdminGetUsers } from "../queries/private/users/superAdminGetUsers"
import { createUserReference } from "../queries/public/user/createUserReference"
import { userActions, userState } from "../state/user/userState"
import { baseApi } from "./baseApi"

const userApi = baseApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        getBLXUser: builder.query<User, void>({
            providesTags: ["User"],
            query: () => ({
                query: getAuthenticatedUser,
                args: {},
                refetchOnMountOrArgChange: true
            }),
            transformErrorResponse: (error) => {
                const user = error?.["data"]?.[0]

                if (user) {
                    if (userState.getState()?.user?.id !== user?.id) {
                        userActions.populateUserState({ user: user })
                        // @TODO: handle this in DD or console instead of UI

                        return user
                    }
                    // @TODO: handle this in DD or console instead of UI
                }
            }
        }),
        updateUser: builder.mutation<User, UpdateUser>({
            invalidatesTags: ["User", "Users"],
            query: (input) => ({
                query: updateUserMutation,
                args: input
            }),
            transformResponse: (data: User): User => {
                useToasts.getState().addToast({ title: "User Updated", variant: "success" })
                return data
            },
            transformErrorResponse: () =>
                useToasts.getState().addToast({ title: "Error Saving User" })
        }),
        createUserReference: builder.mutation<User, InputCreateUserReference>({
            query: (input) => ({
                headerConfig: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-api-key": process.env.NEXT_PUBLIC_AWS_API_KEY
                },
                query: createUserReference,
                args: input
            }),
            transformResponse: (data: User): User => {
                useToasts
                    .getState()
                    .addToast({ title: "User Reference Created", variant: "success" })
                return data
            },
            transformErrorResponse: () =>
                useToasts.getState().addToast({ title: "Error Creatin User Reference" })
        }),
        getUsers: builder.query<User[], void>({
            providesTags: ["Users"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                let nextToken = undefined
                let results: User[] = []
                do {
                    const response = await baseQuery({
                        query: getAllUsersQuery,
                        args: {
                            limit: 750,
                            nextToken
                        }
                    })
                    results = [...results, ...response.data.items]
                    nextToken = response?.data?.nextToken
                } while (nextToken)
                return { data: results }
            }
        }),
        superAdminGetUserById: builder.query<User, InputGetById>({
            providesTags: ["Users", "User"],
            query: (input) => ({
                query: superAdminGetUserById,
                args: input
            })
        }),
        superAdminUpdateUser: builder.mutation<void, InputSuperAdminUpdateUser>({
            invalidatesTags: ["User", "Users"],
            query: (input) => ({
                query: superAdminUpdateUserMutation,
                args: input
            })
        }),
        superAdminGetUsers: builder.query<User[], InputSuperAdminGetUsers>({
            providesTags: ["Users", "User"],
            query: (input) => ({
                query: superAdminGetUsers,
                args: input
            }),
            transformResponse: (data: UserConnection): User[] => {
                return data.items
            }
        })
    })
})

const {
    useGetBLXUserQuery,
    useLazyGetBLXUserQuery,
    useUpdateUserMutation,
    useCreateUserReferenceMutation,
    useGetUsersQuery,
    useSuperAdminGetUserByIdQuery,
    useSuperAdminUpdateUserMutation,
    useSuperAdminGetUsersQuery
} = userApi

export {
    useCreateUserReferenceMutation,
    useGetBLXUserQuery,
    useLazyGetBLXUserQuery,
    useUpdateUserMutation,
    useGetUsersQuery,
    useSuperAdminGetUserByIdQuery,
    useSuperAdminUpdateUserMutation,
    useSuperAdminGetUsersQuery,
    userApi
}
