export const getMessagesByPPSubIdQuery = /* GraphQL */ `query getMessagesByPPSubId($input: inputGetMessagesByPPSubId!) {
  getMessagesByPPSubId(input: $input) {
    messageCount
    nextToken
    messages {
      id
      code
      type
      source
      kegId
      message
      tapNumber
      tapSensorId
      barTitle
      isArchived
      isAutoClearable
      createdDate
      updatedDate
      timestamp
      timeRead
      timeResolved
      tapSensorsInfo {
        tapSensorId
        gatewayDeviceId
        tapNumber
        barTitle
      }
    }
  }
}`
