export const updateBrewMenuDisplayByIdMutation = /* GraphQL */ `mutation UpdateBrewMenuDisplay($input: inputUpdateBrewMenuDisplay!) {
	updateBrewMenuDisplay(input: $input) {
	  updatedDate
	  title
	  status
	  lastUpdatedBy
	  id
	  displayType
	  cloudinaryAssetId
	  backgroundWatermarkAssetId
	  promotionalImageAssets {
        cloudinaryAssetId
        cloudinaryAssetFileName
      }
	  createdDate
	  createdBy
	  displayTitle
	  menu {
		id
	  }
	  status
	  title
	  updatedDate
	}
  }
`
