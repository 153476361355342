import { toast } from "sonner"
import {
    type Brand,
    type CreateBrand,
    type InputGetById,
    type InputSearchBpl,
    OpenSearchType,
    type UpdateBrand,
    type WildCardTitleSearchConnection
} from "types"
import { datadogLocalErrorLog } from "../datadog/datadogErrorLogger"
import { archiveBrandMutation } from "../mutations/private/brand/archiveBrandMutation"
import { createBrandMutation } from "../mutations/private/brand/createBrandMutation"
import { updateBrandMutation } from "../mutations/private/brand/updateBrandMutation"
import { getBrandByIdBranding } from "../queries/private/brand/getBrandByIdBranding"
import { getBrandByIdPL } from "../queries/private/brand/getBrandByIdPL"
import { brandByIdQueryDashboard } from "../queries/private/brand/getBrandByIdQuery-DashBoard"
import { getBrandByIdQuery } from "../queries/private/brand/getBrandByIdQuery-General"
import { getLocations } from "../queries/private/brand/getBrandByIdQuery-Locations"
import { getBrandById } from "../queries/private/brand/getBrandByIdQuery-MMB-Dashboard"
import { allProductsQuery } from "../queries/private/brand/getBrandByIdQuery-allProducts"
import { getLocationsQuery } from "../queries/private/brand/getLocationsQuery"
import { searchBPBrandsQuery } from "../queries/private/brand/searchBPBrandsQuery"
import { searchBPLQuery } from "../queries/private/brand/searchBPLQuery"
import { searchBrandsQuery } from "../queries/private/brand/searchBrandsQuery"
import { brandActions, userActions } from "../state"
import { baseApi } from "./baseApi"

const brandApiLog = datadogLocalErrorLog("brandApi")

const brandApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        searchBrands: builder.query<Brand[], string>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: searchBrandsQuery,
                args: {
                    limit: 750,
                    typeFilters: [OpenSearchType.Brand],
                    search: {
                        wildcardText: "",
                        searchFieldsBrands: {
                            title: input
                        }
                    }
                }
            }),
            transformResponse: (data: WildCardTitleSearchConnection) => {
                return data.items as Brand[]
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to search brands")
                brandApiLog("searchBrands")(error, meta)
            }
        }),
        searchBPL: builder.query<WildCardTitleSearchConnection, InputSearchBpl>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: searchBPLQuery,
                args: input
            }),
            transformResponse: (
                data: WildCardTitleSearchConnection
            ): WildCardTitleSearchConnection => {
                return data as WildCardTitleSearchConnection
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to search BPL")
                brandApiLog("searchBPL")(error, meta)
            }
        }),
        searchBPBrands: builder.query<WildCardTitleSearchConnection, InputSearchBpl>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: searchBPBrandsQuery,
                args: input
            }),
            transformResponse: (
                data: WildCardTitleSearchConnection
            ): WildCardTitleSearchConnection => {
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to search BP brands")
                brandApiLog("searchBPBrands")(error, meta)
            }
        }),
        getAssociatedBrand: builder.query<Brand, InputGetById>({
            providesTags: ["AssociatedBrands"],
            query: (input) => ({
                query: getBrandById,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                userActions.setUserBrandAssociations(data)
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to get associated brand")
                brandApiLog("getAssociatedBrand")(error, meta)
            }
        }),
        getBrandById: builder.query<Brand, InputGetById>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: brandByIdQueryDashboard,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                brandActions.populateBrandState({ brand: data })
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to get brand by ID")
                brandApiLog("getBrandById")(error, meta)
            }
        }),
        getBrandByIdAllProducts: builder.query<Brand, InputGetById>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: allProductsQuery,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to get brand by ID (all products)")
                brandApiLog("getBrandByIdAllProducts")(error, meta)
            }
        }),
        getBrandByIdMMBDashboard: builder.query<Brand, InputGetById>({
            providesTags: ["Brand", "Locations", "Products"],
            query: (input) => ({
                query: brandByIdQueryDashboard,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to get brand by ID (MMB dashboard)")
                brandApiLog("getBrandByIdMMBDashboard")(error, meta)
            }
        }),
        getBrandByIdPP: builder.query<Brand, InputGetById>({
            providesTags: ["Brand", "Locations", "Products"],
            query: (input) => ({
                query: getBrandByIdQuery,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to get brand by ID (PP)")
                brandApiLog("getBrandByIdPP")(error, meta)
            }
        }),
        getBrandByIdLocations: builder.query<Brand, InputGetById>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: getLocations,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to get brand by ID (locations)")
                brandApiLog("getBrandByIdLocations")(error, meta)
            }
        }),
        getLocationsBrandById: builder.query<Brand, InputGetById>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: getLocationsQuery,
                args: { ...input }
            }),
            transformResponse: (data: Brand): Brand => {
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to get locations brand by ID")
                brandApiLog("getLocationsBrandById")(error, meta)
            }
        }),
        getBrandByIdBranding: builder.query<Brand, InputGetById>({
            providesTags: ["Brand"],
            query: (input) => ({
                query: getBrandByIdBranding,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                return data
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to load brand")
                brandApiLog("getBrandByIdBranding")(error, meta)
            }
        }),
        getBrandByIdPL: builder.query<Brand, InputGetById>({
            providesTags: ["Brand"],
            query: (input) => ({
                query: getBrandByIdPL,
                args: input
            }),
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to load brand")
                brandApiLog("getBrandByIdPL")(error, meta)
            }
        }),
        updateBrand: builder.mutation<Brand, UpdateBrand>({
            query: (input) => ({
                query: updateBrandMutation,
                args: input
            }),
            transformResponse: (brandData: Brand): Brand => {
                toast.success("Brand updated")
                // This is problematic.
                brandActions.populateBrandState({
                    brand: {
                        ...brandActions.getBrand,
                        ...brandData
                    }
                })

                return brandData
            },
            invalidatesTags: ["Brand", "Products"],
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to update brand")
                brandApiLog("updateBrand")(error, meta)
            }
        }),
        PLUpdateBrand: builder.mutation<Brand, UpdateBrand>({
            invalidatesTags: ["Brand", "Products"],
            query: (input) => ({
                query: updateBrandMutation,
                args: input
            }),
            transformResponse: (brandData: Brand): Brand => {
                toast.success("Brand updated")
                return brandData
            },
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to update brand (PL)")
                brandApiLog("PLUpdateBrand")(error, meta)
            }
        }),

        createBrand: builder.mutation<Brand, CreateBrand>({
            invalidatesTags: ["Brand", "Products"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                const socialArray = Object.entries(arg?.socialMedia)
                    .map(([key, value]) => {
                        if (value)
                            return {
                                platform: key,
                                handle: value as unknown as string
                            }
                    })
                    .filter((social) => social !== undefined)

                arg.socialMedia = socialArray

                const { data } = await baseQuery({
                    query: createBrandMutation,
                    args: arg
                })

                return { data: data as Brand }
            }
        }),
        archiveBrand: builder.mutation<{ id: string }, string>({
            invalidatesTags: ["Brand", "Brands", "Locations", "Products"],
            query: (brandId) => ({
                query: archiveBrandMutation,
                args: {
                    brandId
                }
            }),
            transformErrorResponse: (error, meta) => {
                toast.error("Failed to archive brand")
                brandApiLog("archiveBrand")(error, meta)
            }
        })
    })
})

const {
    useGetBrandByIdAllProductsQuery,
    useGetBrandByIdLocationsQuery,
    useGetBrandByIdMMBDashboardQuery,
    useGetBrandByIdPPQuery,
    useGetBrandByIdQuery,
    useGetBrandByIdBrandingQuery,
    useGetLocationsBrandByIdQuery,
    useLazyGetBrandByIdAllProductsQuery,
    useLazyGetBrandByIdLocationsQuery,
    useLazyGetBrandByIdMMBDashboardQuery,
    useLazyGetBrandByIdPPQuery,
    useLazyGetBrandByIdQuery,
    useLazyGetBrandByIdBrandingQuery,
    useLazyGetLocationsBrandByIdQuery,
    useLazySearchBPBrandsQuery,
    useLazySearchBPLQuery,
    useLazySearchBrandsQuery,
    useSearchBPBrandsQuery,
    useSearchBPLQuery,
    useSearchBrandsQuery,
    useGetBrandByIdPLQuery,
    useUpdateBrandMutation,
    usePLUpdateBrandMutation,
    useCreateBrandMutation,
    useArchiveBrandMutation
} = brandApi

export {
    brandApi,
    useCreateBrandMutation,
    useGetBrandByIdAllProductsQuery,
    useGetBrandByIdBrandingQuery,
    useGetBrandByIdLocationsQuery,
    useGetBrandByIdMMBDashboardQuery,
    useGetBrandByIdPLQuery,
    useGetBrandByIdPPQuery,
    useGetBrandByIdQuery,
    useGetLocationsBrandByIdQuery,
    useLazyGetBrandByIdAllProductsQuery,
    useLazyGetBrandByIdBrandingQuery,
    useLazyGetBrandByIdLocationsQuery,
    useLazyGetBrandByIdMMBDashboardQuery,
    useLazyGetBrandByIdPPQuery,
    useLazyGetBrandByIdQuery,
    useLazyGetLocationsBrandByIdQuery,
    useLazySearchBPBrandsQuery,
    useLazySearchBPLQuery,
    useLazySearchBrandsQuery,
    usePLUpdateBrandMutation,
    useSearchBPBrandsQuery,
    useSearchBPLQuery,
    useSearchBrandsQuery,
    useUpdateBrandMutation,
    useArchiveBrandMutation
}
