import { type PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import type { PerformancePlatformSubscription, User } from "types"

const initialState: {
    ppSubscriptions: User["ppSubscriptions"]
    bmSubscriptions: User["bmSubscriptions"]
    mmbBrands: User["mmbBrands"]
    ppSubscriptionCache: PerformancePlatformSubscription[]
} = {
    ppSubscriptions: [],
    bmSubscriptions: [],
    mmbBrands: [],
    ppSubscriptionCache: []
}

export const persistedUserSubscriptionsSlice = createSlice({
    name: "persisted-user-subscriptions",
    initialState,
    reducers: {
        setPersistedPPSubscriptions: (state, action: PayloadAction<User["ppSubscriptions"]>) => {
            state.ppSubscriptions = action.payload
        },
        setPersistedBMSubscriptions: (state, action: PayloadAction<User["bmSubscriptions"]>) => {
            state.bmSubscriptions = action.payload
        },
        setPersistedMMBBrands: (state, action: PayloadAction<User["mmbBrands"]>) => {
            state.mmbBrands = action.payload
        },
        setPPSubscriptionCache: (
            state,
            action: PayloadAction<PerformancePlatformSubscription[]>
        ) => {
            state.ppSubscriptionCache = action.payload
        }
    },
    selectors: {
        // State
        userPPSubscriptions: ({ ppSubscriptions }): User["ppSubscriptions"] => ppSubscriptions,
        userBMSubscriptions: ({ bmSubscriptions }): User["bmSubscriptions"] => bmSubscriptions,
        userMMBBrands: ({ mmbBrands }): User["mmbBrands"] => mmbBrands,
        ppSubscriptionCache: ({ ppSubscriptionCache }): PerformancePlatformSubscription[] =>
            ppSubscriptionCache
    }
})

export const makePPSubscriptionFromCache = () =>
    createSelector(
        [persistedUserSubscriptionsSlice.selectors.ppSubscriptionCache, (_, subId) => subId],
        (subscriptionCache, subId) => {
            subscriptionCache[subId] || undefined
        }
    )

export const { setPersistedPPSubscriptions, setPersistedBMSubscriptions, setPersistedMMBBrands } =
    persistedUserSubscriptionsSlice.actions

export const { userPPSubscriptions, userBMSubscriptions, userMMBBrands, ppSubscriptionCache } =
    persistedUserSubscriptionsSlice.selectors

export default persistedUserSubscriptionsSlice
