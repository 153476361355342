"use client"
import type { Keg } from "types"
import { useGetArchivedKegsQuery } from "../../../api/inventoryApi"
import { selectActivePpSubId } from "../../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../../store"
import { type InventoryState, inventoryActions, inventoryState } from "../inventoryState"

export interface useArchivedKegsHook extends Partial<InventoryState> {
    isFetching: boolean
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    archivedKegs: Keg[]
}

export const useArchivedKegs = (): useArchivedKegsHook => {
    const selectedKeg = inventoryState.use.selectedKeg()
    const selectKeg = inventoryActions.selectKeg

    const ppSubId = useAppSelector(selectActivePpSubId)

    const {
        data: archivedKegs,
        isLoading,
        isError,
        isFetching,
        isSuccess
    } = useGetArchivedKegsQuery({ ppSubId }, { skip: !ppSubId })

    return {
        archivedKegs,
        selectKeg,
        selectedKeg,
        isLoading,
        isSuccess,
        isError,
        isFetching
    }
}
