export const getThroughputDetailsByPPSub = `
query GetThroughputDetailsByPPSub($input: inputGetThroughputByPPSub!) {
	getThroughputDetailsByPPSub(input: $input){
		throughputDetails {
			throughput
			timestamp
		}
		totalThroughput
	}
}
`
