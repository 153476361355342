import Fuse from "fuse.js"
import { useMemo } from "react"
import type { Keg } from "types"
import { sortKegsByReceivedDate } from "utilities"
import { usePreviouslyReceivedKegs } from "."

export const useSearchPreviouslyReceivedKegs = (
    ppSubId: string,
    searchTerm: string,
    timeFrameDays: number = 90
) => {
    const { previouslyReceivedKegConnection, isLoadingPreviouslyReceivedKegs } =
        usePreviouslyReceivedKegs(ppSubId, timeFrameDays)

    const kegs: Keg[] = previouslyReceivedKegConnection?.items?.filter((keg) => !!keg) ?? []

    const sortedKegs: Keg[] = useMemo(() => sortKegsByReceivedDate(kegs), [kegs])

    const fuse = new Fuse(kegs, {
        keys: ["product.title", "product.brand.title"],
        threshold: 0.25,
        useExtendedSearch: true
    })

    return {
        previouslyReceivedKegs: searchTerm
            ? fuse.search(searchTerm).map(({ item }) => item)
            : sortedKegs,
        isLoadingPreviouslyReceivedKegs
    }
}
