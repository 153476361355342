export const updateOrganizationMutation = /* GraphQL */ `
mutation UpdateOrganization($input: inputUpdateOrganization!) {
	updateOrganization(input: $input){
        __typename
        id
        title
        updatedDate
    }
}
`
