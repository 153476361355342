export const getOrganizationPricingPresets = /* GraphQL */ `query GetOrganizationPricingPresets($input: inputGetOrganizationPricingPresets!) {
	getOrganizationPricingPresets(input: $input){
		items {
			updatedDate
			lastUpdatedBy
			id
			createdDate
			createdBy
			isDefault
			servings {
			    isActive
			    isDefault
			    isDisplayed
			    label
			    oz
			    pourSize
			    price
			    salePrice
			    servingType
			    showServingType
		    }
			title
		}
		nextToken
		returnedCount
	}
}`
