import type {
    InputCreateOrganizationPricingPreset,
    InputDeleteOrganizationPricingPreset,
    InputUpdateOrganizationPricingPreset
} from "types"
import {
    useCreateOrganizationPricingPresetMutation,
    useDeleteOrganizationPricingPresetMutation,
    useUpdateOrganizationPricingPresetMutation
} from "../../api"

import { useBLXUser } from "../../state"

export const usePricingPresetMutations = () => {
    const [createPricingPresetMutation] = useCreateOrganizationPricingPresetMutation()
    const [deletePricingPresetMutation] = useDeleteOrganizationPricingPresetMutation()
    const [updatePricingPresetMutation] = useUpdateOrganizationPricingPresetMutation()
    const { orgId } = useBLXUser()

    const createPricingPreset = async (
        input: Omit<InputCreateOrganizationPricingPreset, "organizationId">
    ) => {
        await createPricingPresetMutation({
            organizationId: orgId,
            ...input
        })
    }

    const updatePricingPreset = async (
        input: Omit<InputUpdateOrganizationPricingPreset, "organizationId">
    ) => {
        await updatePricingPresetMutation({
            organizationId: orgId,
            ...input
        })
    }

    const deletePricingPreset = async (
        input: Omit<InputDeleteOrganizationPricingPreset, "organizationId">
    ) => {
        await deletePricingPresetMutation({
            organizationId: orgId,
            ...input
        })
    }

    return {
        createPricingPreset,
        updatePricingPreset,
        deletePricingPreset
    }
}
