import type {
    BrewMenuDisplay,
    BrewMenuDisplayConnection,
    InputByBmSubId,
    InputCreateBrewMenuDisplay,
    InputDeleteBrewMenuDisplay,
    InputGetBrewMenuDisplays,
    InputGetById,
    InputGetBySlug,
    InputUpdateBrewMenuDisplay
} from "types"
import { deleteBrewMenuDisplayMutation } from "utilities"
import { datadogLocalErrorLog } from "../../datadog/datadogErrorLogger"
import { createBrewMenuDisplayMutation } from "../../mutations/private/brewmenu/createBrewMenuDisplayMutation"
import { updateBrewMenuDisplayByIdMutation } from "../../mutations/private/brewmenu/updateBrewMenuDisplayByIdMutation"
import { getBrewMenuDisplayByIdQuery } from "../../queries/private/brewmenu/getBrewMenuDisplayByIdQuery"
import { getBrewMenuDisplayBySlugsQuery } from "../../queries/private/brewmenu/getBrewMenuDisplayBySlugsQuery"
import { getBrewMenuDisplaysByBMSubIdQuery } from "../../queries/private/brewmenu/getBrewMenuDisplaysByBMSubIdQuery"
import { getBrewMenuDisplaysQuery } from "../../queries/private/brewmenu/getBrewMenuDisplaysQuery"
import { baseApi } from "../baseApi"

const brewMenuDisplayApiLog = datadogLocalErrorLog("brewMenuDisplayApi")

const brewMenuDisplayApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplays  */
        getBrewMenuDisplays: builder.query<BrewMenuDisplayConnection, InputGetBrewMenuDisplays>({
            //! NEEDS: TESTING
            providesTags: ["Menus", "Displays"],
            query: (input) => ({
                query: getBrewMenuDisplaysQuery,
                args: input
            }),
            transformResponse: (data: BrewMenuDisplayConnection): BrewMenuDisplayConnection => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuDisplayApiLog("getBrewMenuDisplays")
        }),
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplaysByBMSubId  */
        getBrewMenuDisplaysByBMSubId: builder.query<BrewMenuDisplayConnection, InputByBmSubId>({
            //! NEEDS: TESTING
            providesTags: ["Displays"],
            query: (input) => ({
                query: getBrewMenuDisplaysByBMSubIdQuery,
                args: input
            }),
            transformResponse: (data: BrewMenuDisplayConnection): BrewMenuDisplayConnection => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuDisplayApiLog("getBrewMenuDisplaysByBMSubId")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplayById */
        getBrewMenuDisplayById: builder.query<BrewMenuDisplay, InputGetById>({
            providesTags: ["BmaDisplayById", "Displays"],
            query: (input) => ({
                query: getBrewMenuDisplayByIdQuery,
                args: input
            }),
            transformErrorResponse: brewMenuDisplayApiLog("getBrewMenuDisplayById")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplayBySlugs  */
        getBrewMenuDisplayBySlugs: builder.query<BrewMenuDisplay[], InputGetBySlug>({
            //! NEEDS: TESTING
            providesTags: ["Menus", "Displays"],
            query: (input) => ({
                query: getBrewMenuDisplayBySlugsQuery,
                args: input
            }),
            transformResponse: (data: BrewMenuDisplay[]): BrewMenuDisplay[] => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuDisplayApiLog("getBrewMenuDisplayBySlugs")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenuDisplay */
        createBrewMenuDisplay: builder.mutation<BrewMenuDisplay | null, InputCreateBrewMenuDisplay>(
            {
                //! NEEDS: TESTING
                query: (input) => ({
                    query: createBrewMenuDisplayMutation,
                    args: input
                }),
                invalidatesTags: ["Displays"],
                transformResponse: (data): BrewMenuDisplay | null => {
                    // TODO: Add notifications
                    //! NEEDS: brewMenuActions
                    // @ts-ignore
                    return data
                },
                transformErrorResponse: brewMenuDisplayApiLog("createBrewMenuDisplay")
            }
        ),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteBrewMenuDisplay */
        deleteBrewMenuDisplay: builder.mutation<BrewMenuDisplay[], InputDeleteBrewMenuDisplay>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: deleteBrewMenuDisplayMutation,
                args: input
            }),
            invalidatesTags: ["Displays"],
            transformResponse: (data: BrewMenuDisplay[]): BrewMenuDisplay[] => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuDisplayApiLog("deleteBrewMenuDisplay")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenuDisplay */
        updateBrewMenuDisplay: builder.mutation<BrewMenuDisplay[], InputUpdateBrewMenuDisplay>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: updateBrewMenuDisplayByIdMutation,
                args: input
            }),
            invalidatesTags: ["Displays"],
            transformResponse: (data: BrewMenuDisplay[]): BrewMenuDisplay[] => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuDisplayApiLog("updateBrewMenuDisplay")
        })
    })
})

const {
    useCreateBrewMenuDisplayMutation,
    useDeleteBrewMenuDisplayMutation,
    useGetBrewMenuDisplayByIdQuery,
    useGetBrewMenuDisplayBySlugsQuery,
    useGetBrewMenuDisplaysByBMSubIdQuery,
    useGetBrewMenuDisplaysQuery,
    useLazyGetBrewMenuDisplayBySlugsQuery,
    useLazyGetBrewMenuDisplaysQuery,
    useUpdateBrewMenuDisplayMutation
} = brewMenuDisplayApi

export {
    brewMenuDisplayApi,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createBrewMenuDisplay */
    useCreateBrewMenuDisplayMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteBrewMenuDisplay */
    useDeleteBrewMenuDisplayMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplayById */
    useGetBrewMenuDisplayByIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplayBySlugs  */
    useGetBrewMenuDisplayBySlugsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplaysByBMSubId */
    useGetBrewMenuDisplaysByBMSubIdQuery,
    /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplays  */
    useGetBrewMenuDisplaysQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplayBySlugs  */
    useLazyGetBrewMenuDisplayBySlugsQuery,
    /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplays  */
    useLazyGetBrewMenuDisplaysQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrewMenuDisplay */
    useUpdateBrewMenuDisplayMutation
}
