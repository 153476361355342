import { useGetBrewMenuByIdQuery } from "../../../api"

export const useBrewMenuMenuById = (menuId: string, bmSubId: string) => {
    const { data, isError, isFetching, isLoading, isSuccess, refetch } = useGetBrewMenuByIdQuery(
        {
            itemId: menuId,
            parentId: bmSubId
        },
        { skip: !menuId || !bmSubId }
    )

    return {
        menu: data,
        sections: data?.sections || [],
        getBrewMenuRefetch: refetch,
        isErrorBrewMenuMenuById: isError,
        isFetchingBrewMenuMenuById: isFetching,
        isLoadingBrewMenuMenuById: isLoading,
        isSuccessBrewMenuMenuById: isSuccess
    }
}
