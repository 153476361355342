import { Order, ProductTypeFilter } from "types"
import { useLimitedProductSearchQuery } from "../../api"

export const useUnverifiedProducts = (limit: number = 10) => {
    const { data, isLoading, isFetching } = useLimitedProductSearchQuery({
        brewType: ProductTypeFilter.Unverified,
        orderBy: "createdDate",
        order: Order.Desc,
        limit
    })

    return {
        products: data?.items || [],
        isLoading,
        isFetching
    }
}
