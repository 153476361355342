export const createOrganizationGroupMutation = `
mutation CreateOrganizationGroup($input: inputCreateOrganizationGroup!) {
	createOrganizationGroup(input: $input){
		id
		slug
		title
		bmSubId
		brandId
		cloudinaryAssetId
		ppSubId
	}
}`
