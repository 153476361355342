export const getVesselsQuery = /* GraphQL */ `query GetVessels($input: inputGetAll!) {
	getVessels(input: $input){
		items {
			awrSleep
			awrWeight
			couplerWeight
			createdBy
			createdDate
			displayOrder
			iconSlug
			id
			label
			lastUpdatedBy
			material
			shortTitle
			slug
			suggestedTotalVolume
			tareWeight
			title
			totalVolume
			updatedDate
		}
		nextToken
		returnedCount
		totalResults
	}
}`
