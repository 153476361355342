import { useOrganizationSearchStore } from "./useOrganizationSearchStore"
import { useSortedOrganizations } from "./useSortedOrganizations"

const PAGE_SIZE = 10

export const usePaginatedOrgSearch = () => {
    const { page } = useOrganizationSearchStore()
    const sortedData = useSortedOrganizations()

    const pageResults = sortedData.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
    const totalPages = Math.ceil(sortedData.length / PAGE_SIZE)

    return {
        pageResults,
        totalPages
    }
}
