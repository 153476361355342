import type { PerformancePlatformSubscription } from "types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

export interface IPPSubState {
    ppSubId: string
    ppSub: PerformancePlatformSubscription
}

export interface IPPSubActions {
    setPPSubId: ({ ppSubId }: { ppSubId: string }) => void
    setPPSubData: ({ ppSubData }: { ppSubData: PerformancePlatformSubscription }) => void
    resetPPSubState: () => void
    populatePPSubState: ({ ppSub }: { ppSub?: PerformancePlatformSubscription }) => void
}

const initialValues: IPPSubState = {
    ppSubId: "",
    ppSub: null
}
/**
 * @deprecated The 'ppSubState' object is deprecated and will be removed in future versions.
 * Please use the new state management approach.
 */
export const ppSubState = create<IPPSubState & IPPSubActions>()(
    devtools(
        (set) => ({
            ...initialValues,
            setPPSubId: ({ ppSubId }) => {
                console.warn(
                    "Warning: 'setPPSubId' is deprecated and will be removed in future versions."
                )
                set({ ppSubId })
            },
            setPPSubData: ({ ppSubData }) => {
                console.warn(
                    "Warning: 'setPPSubData' is deprecated and will be removed in future versions."
                )
                set({ ppSub: ppSubData })
            },
            populatePPSubState: ({ ppSub }) => {
                console.warn(
                    "Warning: 'populatePPSubState' is deprecated and will be removed in future versions."
                )
                set({
                    ppSub,
                    ppSubId: ppSub?.id
                })
            },
            resetPPSubState: () => {
                console.warn(
                    "Warning: 'resetPPSubState' is deprecated and will be removed in future versions."
                )
                set({ ...initialValues })
            }
        }),
        { name: "ppSubState" }
    )
)

/**
 * @deprecated The 'ppSubActions' object is deprecated and will be removed in future versions.
 * Please use the new state management approach.
 */
export const ppSubActions = {
    setPPSub: ppSubState.getState().setPPSubId,
    setPPSubData: ppSubState.getState().setPPSubData,
    populatePPSubState: ppSubState.getState().populatePPSubState,
    resetPPSubState: ppSubState.getState().resetPPSubState
}
