import { toast } from "sonner"
import type {
    InputGetThroughputByKegId,
    InputGetThroughputByPpSub,
    InputGetThroughputByProduct,
    InputGetTotalThroughput,
    KegThroughput,
    PpSubThroughputDetails,
    PpSubThroughputSummary,
    ProductThroughput,
    ProductThroughputDetail,
    TotalProductThroughput,
    TotalTapThroughput
} from "types"
import { getTotalProductThroughput } from "../queries/private/throughput"
import { getThroughputByKegId } from "../queries/private/throughput/getThroughputByKegId"
import { getThroughputByProduct } from "../queries/private/throughput/getThroughputByProduct"
import { getThroughputDetailsByPPSub } from "../queries/private/throughput/getThroughputDetailsByPPSub"
import { getThroughputSummaryByPPSub } from "../queries/private/throughput/getThroughputSummaryByPPSub"
import { getTotalTapThroughput } from "../queries/private/throughput/getTotalTapThroughput"
import { baseApi } from "./baseApi"

const throughPutApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getThroughputByProduct: builder.query<
            ProductThroughputDetail[],
            InputGetThroughputByProduct
        >({
            providesTags: ["ThroughPutByProduct", "throughput"],
            query: (input) => ({
                query: getThroughputByProduct,
                args: { ...input }
            }),
            transformResponse: (data: ProductThroughput): ProductThroughputDetail[] => {
                // TODO: Add notifications
                return data?.productThroughput || []
            },
            transformErrorResponse: () =>
                toast.error("There was an issue getting the throughput by product")
        }),
        getThroughputByKegId: builder.query<KegThroughput, InputGetThroughputByKegId>({
            providesTags: ["ThroughputByKegId"],
            query: (input) => ({
                query: getThroughputByKegId,
                args: { ...input }
            }),
            transformErrorResponse: () =>
                toast.error("There was an issue getting the throughput by keg id")
        }),
        getThroughputDetailsByPPSub: builder.query<
            PpSubThroughputDetails,
            InputGetThroughputByPpSub
        >({
            query: (input) => ({
                query: getThroughputDetailsByPPSub,
                args: input
            })
        }),
        getThroughputSummaryByPPSub: builder.query<
            PpSubThroughputSummary,
            InputGetThroughputByPpSub
        >({
            query: (input) => ({
                query: getThroughputSummaryByPPSub,
                args: input
            })
        }),
        getTotalProductThroughput: builder.query<TotalProductThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalProductThroughput,
                args: input
            })
        }),
        getTotalTapThroughput: builder.query<TotalTapThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalTapThroughput,
                args: input
            })
        })
    })
})

const {
    useGetThroughputByProductQuery,
    useGetThroughputByKegIdQuery,
    useGetThroughputDetailsByPPSubQuery,
    useGetThroughputSummaryByPPSubQuery,
    useGetTotalProductThroughputQuery,
    useGetTotalTapThroughputQuery
} = throughPutApi

export {
    throughPutApi,
    useGetThroughputByKegIdQuery,
    useGetThroughputByProductQuery,
    useGetThroughputDetailsByPPSubQuery,
    useGetThroughputSummaryByPPSubQuery,
    // https://blx-docs-mono-dev.vercel.app/docs/private/query/getTotalProductThroughput
    useGetTotalProductThroughputQuery,
    useGetTotalTapThroughputQuery
}
