export const createOrganizationForSelfMutation = /* GraphQL */ `mutation CreateOrganizationForSelf($input: inputCreateOrganizationForSelf!) {
	createOrganizationForSelf(input: $input){
		updatedDate
		lastUpdatedBy
		id
		createdDate
		createdBy
		address {
			city
			country
			# geo { ...geo }
			postalCode
			state
			street
			street2
		}
		billing {
			# address { ...address }
			billingCycle
			billingId
			# contact { ...contact }
			lastPaymentDate
			nextPaymentDate
			paymentMethod
		}
		brands {
			# address { ...address }
			# assets { ...assets }
			baId
			# contact { ...contact }
			createdBy
			createdDate
			description
			displayTitle
			# featuredBrew { ...featuredBrew }
			guid
			id
			lastUpdatedBy
			# locations { ...locations }
			# mediaLibrary { ...mediaLibrary }
			# organization { ...organization }
			# products { ...products }
			slug
			# socialMedia { ...socialMedia }
			subtitle
			title
			# traits { ...traits }
			updatedDate
			website
			yearEstablished
		}
		brewMenuSubscriptions {
			brandId
			createdBy
			createdDate
			# displays { ...displays }
			hubSpotId
			id
			isActive
			lastUpdatedBy
			locationIds
			# logo { ...logo }
			# menus { ...menus }
			# organization { ...organization }
			ppSubId
			# settings { ...settings }
			slug
			subscriptionTier
			title
			updatedDate
		}
		contact {
			email
			phone
		}
		externalId
		hubspotId
		logo {
			# bmSubLogoAssignments { ...bmSubLogoAssignments }
			cloudinaryId
			createdBy
			createdDate
			id
			lastUpdatedBy
			# menuItemAssetAssignments { ...menuItemAssetAssignments }
			# organizationLogoAssignments { ...organizationLogoAssignments }
			# ppSubLogoAssignments { ...ppSubLogoAssignments }
			shared
			tags
			updatedDate
		}
		owner {
			# address { ...address }
			bdbAccess
			# bmSubscriptions { ...bmSubscriptions }
			# boards { ...boards }
			brewlogixSuperadmin
			# contact { ...contact }
			contactPreference
			createdBy
			createdDate
			defaultSubscription
			description
			familyName
			# favorites { ...favorites }
			givenName
			id
			isActive
			jobTitle
			lastUpdatedBy
			meta
			# mmbBrands { ...mmbBrands }
			# organization { ...organization }
			# ppSubscriptions { ...ppSubscriptions }
			registrationSource
			# routes { ...routes }
			slug
			trustedUser
			updatedDate
		}
		ppSubscriptions {
			# address { ...address }
			brewMenuTier
			createdBy
			createdDate
			expirationDate
			# gateways { ...gateways }
			hubSpotId
			id
			# kegs { ...kegs }
			lastUpdatedBy
			locationType
			# logo { ...logo }
			numberOfTapSensors
			onlyPriorityBrands
			# organization { ...organization }
			# priorityBrands { ...priorityBrands }
			# settings { ...settings }
			slug
			# tapSensors { ...tapSensors }
			title
			updatedDate
		}
		slug
		status
		title
		users {
			# address { ...address }
			bdbAccess
			# bmSubscriptions { ...bmSubscriptions }
			# boards { ...boards }
			brewlogixSuperadmin
			# contact { ...contact }
			contactPreference
			createdBy
			createdDate
			defaultSubscription
			description
			familyName
			# favorites { ...favorites }
			givenName
			id
			isActive
			jobTitle
			lastUpdatedBy
			meta
			# mmbBrands { ...mmbBrands }
			# organization { ...organization }
			# ppSubscriptions { ...ppSubscriptions }
			registrationSource
			# routes { ...routes }
			slug
			trustedUser
			updatedDate
		}
	}
}`
