export const createUserReference = /* GraphQL */ `mutation CreateUserReference($input: inputCreateUserReference!) {
	createUserReference(input: $input){
		address {
			city
			country
			
			postalCode
			state
			street
			street2
		}
		contact {
			email
			phone
		}
		createdBy
		createdDate
		familyName
		givenName
		id
		isActive
		lastUpdatedBy
		updatedDate
	}
}`
