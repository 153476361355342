import { type PayloadAction, createSlice } from "@reduxjs/toolkit"
import type { TrackedVarianceReport } from "types"

const initialState: TrackedVarianceReport = {
    createdBy: "",
    createdDate: "",
    endDate: "",
    id: "",
    lastUpdatedBy: "",
    organizationId: "",
    ppSubId: "",
    rawFilenames: [],
    startDate: "",
    status: null,
    title: "",
    updatedDate: "",
    report: {
        totals: {
            absVariance: 0,
            computedYield: 0,
            costDepleted: 0,
            label: "",
            ozPoured: 0,
            ozSold: 0,
            ozVariance: 0,
            revenue: 0,
            revenuePerOz: 0,
            revenueVariance: 0,
        },
        rows: [],
        metadata: {
            avgYield: 0,
            totalRevenueVariance: 0,
            totalOzVariance: 0,
            totalYield: 0,
        }
    }
}

const varianceReportSlice = createSlice({
    name: "active-variance-report",
    initialState,
    reducers: {
        setActiveVarianceReport: (state, action: PayloadAction<TrackedVarianceReport>) => {
            state.title = action.payload.title ?? ""
            state.rawFilenames = action.payload.rawFilenames ?? []
            state.id = action.payload.id ?? ""
            state.report = action.payload.report ?? initialState.report
        },
        clearActiveVarianceReport: () => {
            return initialState
        }
    }
})

export const { setActiveVarianceReport, clearActiveVarianceReport } = varianceReportSlice.actions

export default varianceReportSlice
