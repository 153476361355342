export const createOrganizationPricingPreset = /* GraphQL */ `mutation CreateOrganizationPricingPreset($input: inputCreateOrganizationPricingPreset) {
	createOrganizationPricingPreset(input: $input){
		updatedDate
		lastUpdatedBy
		id
		createdDate
		createdBy
		isDefault
		servings {
			isActive
			isDefault
			isDisplayed
			label
			oz
			pourSize
			price
			salePrice
			servingType
			showServingType
		}
		title
	}
}`
