export const getSuperadminVarianceReportsQuery = /* GraphQL */ `
query SuperAdminGetVarianceReports($input: inputSuperAdminGetVarianceReports!) {
    superAdminGetVarianceReports(input: $input) {
      items {
        createdBy
        createdDate
        endDate
        errors
        id
        lastUpdatedBy
        ppSubId
        organizationId
        rawFilenames
        report {
          metadata {
            avgYield
            totalRevenueVariance
            # totalVariance
            totalYield
          }
          rows {
            absVariance
            computedYield
            costDepleted
            label
            ozPoured
            ozSold
            ozVariance
            revenue
            revenuePerOz
            revenueVariance
          }
          totals {
            absVariance
            computedYield
            costDepleted
            label
            ozPoured
            ozSold
            ozVariance
            revenue
            revenuePerOz
            revenueVariance
          }
        }
        s3PreProcessedUploadFields {
          fields
          url
        }
        s3RawUploadFields {
          fields
          url
        }
        startDate
        status
        title
        updatedDate
      }
      nextToken
      returnedCount
    }
  }
`
