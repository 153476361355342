export const getProductLocationsQuery = /* GraphQL */ `
query GetProductLocations($input: inputGetProductLocations) {
  getProductLocations(input: $input) {
    items {
      available
      canned
      growler
      onTap
      location {
        id
        title
      }
    }
    returnedCount
    nextToken
  }
}
`
