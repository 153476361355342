export const createDiscountPresetMutation = /* GraphQL */ `mutation CreateDiscountPreset($input: inputCreateDiscountPreset!) {
	createDiscountPreset(input: $input){
		updatedDate
		title
		lastUpdatedBy
		id
		createdDate
		createdBy
		dateTimeActive {
			anyDate
			days
			endDate
			# hoursAvailable { ...hoursAvailable }
			startDate
		}
		fixedAmount
		percent
		pourSizes
		type
	}
}`
