export const getBrewMenuDisplaysByBMSubIdQuery = /* GraphQL */ `query GetBrewMenuDisplaysByBMSubId($input: inputByBMSubId!) {
    getBrewMenuDisplaysByBMSubId(input: $input) {
      items {
        createdBy
        createdDate
        displayTitle
        displayType
        id
        lastUpdatedBy
        menu {
          activeDates
          createdBy
          createdDate
          displays {
            createdBy
            id
            title
            lastUpdatedBy
            updatedDate
            displayTitle
            displayType
            status
          }
          id
          lastUpdatedBy
          locationId
          ppSubId
          status
          title
          updatedDate
        }
        slug
        status
        title
        updatedDate
      }
      nextToken
      returnedCount
    }
  }`
