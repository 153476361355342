export const updateProduct = /* GraphQL */ `mutation  updateProduct ($input:  updateProduct!) {
   updateProduct (input: $input) {
		__typename
		id
		abv
		color
		createdDate
		hintsOfFlavorNotes 
		isVerified
		isNewProduct
		isHiddenExternally
		primaryFlavorNotes
		releaseDate
		servingTemperature 
		servingVessels
		tastingExperience
		updatedDate
      	description
		foodPairingList {
			category
  			title
		}
      	slug
      	title
		assets {
			__typename
			id
			alt
			type
			updatedDate
			createdDate
			createdBy
			bytes 
			width
			height
			tags
			... on S3Asset {
			path
			filename
			}
			... on CloudinaryAsset {
				cloudinaryId
				caption
				category
			}
		}
		brand {
			id
			title
			displayTitle
			address {
				street
				city
				state
			}
		}
		ingredients {
		  title
		  type
		}    
		traits {
			allergens
			availability
			nutrition {
				calories
				carbohydrates
				cholesterol
				dietaryFiber
				fat
				protein
				saturatedFat
				sodium
				transFat
				sugars
			}
			otherTraits
		}
		... on Beer {
			originalGravity
			srm
			ibu
			style {
				id
				title
				category {
					title
				}
				slug
				label
				primaryFlavorNotes
				#description
			}
		}
		... on Kombucha {
			acidity 
			appearance 
			body 
			brixCount 			
			carbonation 
			kombuchaFamily 
			perceivedSweetness 
			probiotics 
			sourness 
			teaCharacter
		}
		... on Cider {
			ciderFamily 
			acidity 
			perceivedSweetness 
			body 
			tannin 
			brixCount 
			carbonation 
		}
	}
}`
