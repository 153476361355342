import type { InputGetTotalThroughput } from "types"
import { useGetTotalTapThroughputQuery } from "../../../../api"
import getFormattedStartAndEndDateStringsFromADateRange from "../../../../utilities/getFormattedStartAndEndDateStringsFromADateRange"
import { usePPSub } from "../../../ppSubscription"

/**
 * Custom hook to fetch throughput data by tap and date range.
 *
 * @param dateRange - Optional array of two Date objects representing the start and end dates.
 * @returns An object containing throughput data and various states of the data fetching process.
 *
 * @property throughputByTapHookData - The fetched throughput data.
 * @property isFetchingThroughputByTap - Boolean indicating if the data is currently being fetched.
 * @property refetchThroughputByTap - Function to refetch the throughput data.
 * @property isLoadingThroughputByTap - Boolean indicating if the data is in the loading state.
 * @property isErrorThroughputByTap - Boolean indicating if there was an error fetching the data.
 */

export const useThroughputByTap = (dateRange?: Date[]) => {
    const { ppSubId, ppSubApiData } = usePPSub()
    const { startDate, endDate } = getFormattedStartAndEndDateStringsFromADateRange({
        dateRange,
        timezone: ppSubApiData?.settings?.timeZone
    })

    const inputData: InputGetTotalThroughput = {
        ppSubId,
        dateRange: {
            startDate,
            endDate
        }
    }

    const { data, isFetching, isLoading, isError, refetch } = useGetTotalTapThroughputQuery(
        inputData,
        {
            skip: (dateRange && (!dateRange[0] || !dateRange[1])) || !ppSubId
        }
    )

    return {
        data,
        isFetching,
        isLoading,
        isError,
        refetch
    }
}
