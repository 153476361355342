export const allProductsQuery = /* GraphQL */ `
query GetBrandById($brandId: ID!) {
    getBrandById(brandId: $brandId) {
        products {
			__typename
			id
			slug
			title
			description
			servingVessels
			assets {
				alt
				path
				type
			}
			ingredients {
				title
				type
			}  
			brand {
				id
				title
				displayTitle
				slug
			}    
			traits {
				allergens
				availability
				nutrition {
				calories
				carbohydrates
				cholesterol
				dietaryFiber
				fat
				protein
				saturatedFat
				sodium
				transFat
				sugars
				}
				otherTraits
			}
			createdDate
			releaseDate
			updatedDate
			abv
			color
			isNewProduct
			... on Beer {
				srm
				ibu
				style {
				id
				title
				category {
					title
				}
				}
			}
			... on Kombucha {
				kombuchaFamily
			}
			... on Cider {
				ciderFamily
			}
		}
    }
  }
`
