export const createBrewMenuDisplayMutation = /* GraphQL */ `mutation CreateBrewMenuDisplay($input: inputCreateBrewMenuDisplay!) {
  createBrewMenuDisplay(input: $input) {
    createdBy
    createdDate
    displayTitle
    displayType
    id
    lastUpdatedBy
    menu {
      activeDates
      createdBy
      createdDate
      id
      lastUpdatedBy
      locationId
      ppSubId
      status
      title
      updatedDate
      title
    }
    settings {
      allowPagination
      backgroundColor
      colorScheme
      columns
      fallbackImage
      mediaType
      font
      numberOfItemsPerScreen
      orientation
      primaryFontColor
      productImage
      rotationInterval
      screenMode
      secondaryFontColor
      sectionBackgroundColor
      sectionFontColor
      showABV
      showBrewery
      showIBU
      showLogoOnAllPages
      showOnDeck
      showPricing
      showPrimaryFlavorNotes
      showProductName
      showSRM
      showSection
      showSize
      showStyle
      showUnknownKegs
      tertiaryFontColor
    }
    slug
    status
    title
    updatedDate
  }
}`
