import type {
    BrewMenuSubscription,
    CreateProduct,
    InputCreateCustomProduct,
    InputUpdateCustomProduct,
    ServingSize
} from "types"
import {
    useCreateBrewMenuCustomProductMutation,
    useCreateBrewMenuUnverifiedProductMutation,
    useUpdateBrewMenuCustomProductMutation
} from "../../../api/brewMenu/brewMenuProductApi"

export const useBrewMenuProductMutations = () => {
    const [createBrewMenuCustomProductMutation] = useCreateBrewMenuCustomProductMutation()
    const [createBrewMenuUnverifiedProductMutation] = useCreateBrewMenuUnverifiedProductMutation()
    const [updateBrewMenuCustomProductMutation] = useUpdateBrewMenuCustomProductMutation()

    const createBrewMenuCustomProduct = (
        product: Omit<InputCreateCustomProduct, "bmSubId">,
        bmSubId: BrewMenuSubscription["id"],
        servings: ServingSize[]
    ) =>
        createBrewMenuCustomProductMutation({
            bmSubId,
            servings,
            ...product
        })

    const updateBrewMenuCustomProduct = async (
        bmSubId: BrewMenuSubscription["id"],
        product: Omit<InputUpdateCustomProduct, "bmSubId">
    ) =>
        updateBrewMenuCustomProductMutation({
            bmSubId,
            bmCustomProductId: product.bmCustomProductId,
            ...product
        })

    const createBrewMenuUnverifiedProduct = (input: CreateProduct) =>
        createBrewMenuUnverifiedProductMutation(input)

    const createBrewMenuProduct = async (
        product: Omit<InputCreateCustomProduct, "bmSubId">,
        bmSubId: BrewMenuSubscription["id"],
        servings: ServingSize[] = []
    ) => createBrewMenuCustomProduct(product, bmSubId, servings)

    return {
        createBrewMenuCustomProduct,
        createBrewMenuUnverifiedProduct,
        createBrewMenuProduct,
        updateBrewMenuCustomProduct
    }
}
