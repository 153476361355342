import { toast } from "sonner"
import {
    createProductMutationInventory
} from '../mutations/private/product/createProductMutation-Inventory'
import { deleteProduct } from '../mutations/private/product/deleteProductMutation'
import updateLocationProductRelationship from '../mutations/private/product/updateLocationProductRelationships'
import { updateProduct } from '../mutations/private/product/updateProductMutation'
import { getProductByIdPPAccount } from '../queries/private/product/getProductById-PP-account'
import {
    getProductByIdBrewInsights
} from '../queries/private/product/getProductById-brewknowledge'
import { getProductByIdQuery } from '../queries/private/product/getProductByIdQuery-PP-inventory'
import { getProductBySlugQuery } from '../queries/private/product/getProductBySlugQuery'
import { getProductLocationsQuery } from '../queries/private/product/getProductLocationsQuery'
import { getProductsByBrandId } from '../queries/private/product/getProductsByBrandId'
import { plGetProductByIdQuery } from '../queries/private/product/plGetProductByIdQuery'
import searchBplProducts from '../queries/private/product/searchBplProducts'
import { allStyles } from '../queries/private/style/getAllStylesQuery-MMB'
import { baseApi } from './baseApi'

import {
    type Brand,
    type CompositeProduct,
    type CreateProduct,
    type DeleteProduct,
    type InputGetAll,
    type InputGetById,
    type InputGetBySlug,
    type InputGetProductLocations,
    type InputLocationProductRelationship,
    type InputSearchBpl,
    type LocationProductRelationship,
    type LocationProductRelationshipConnection,
    OpenSearchType,
    type Order,
    type Product,
    ProductTypeFilter,
    type StyleConnection,
    type UpdateProduct,
    type WildCardTitleSearchConnection
} from "types"
import { getAllBeerStyles } from "utilities"
import { createProduct } from "../mutations/private/product/createProductMutation"
export type LimitedProductSearchInput = {
    searchTerm?: string
    limit?: number
    brewType?: ProductTypeFilter
    order?: Order
    orderBy?: string
    nextToken?: string
}

/**
 * The `productApi` object is an instance of the `baseApi` with injected endpoints for managing products.
 * It provides various queries and mutations to interact with the product-related data.
 *
 * Endpoints:
 * - `getProductBySlug`: Fetches a product by its slug.
 * - `getProductLocations`: Fetches locations related to a product.
 * - `getProductById`: Fetches a product by its ID.
 * - `getProductByIdBrewInsights`: Fetches a product by its ID specifically for Brew Insights.
 * - `getProductByIdPpAccount`: Fetches a product by its ID specifically for PP Account.
 * - `getProductsByBrandId`: Fetches products by a brand ID.
 * - `getAllStyles`: Fetches all product styles.
 * - `createProduct`: Creates a new product.
 * - `createProductPpInventory`: Creates a new product specifically for PP Inventory.
 * - `updateProduct`: Updates an existing product.
 * - `deleteProduct`: Deletes a product.
 * - `updateProductAvailability`: Updates the availability of a product.
 * - `getAllBeerStyles`: Fetches all beer styles.
 * - `limitedProductSearch`: Performs a limited search for products.
 * - `productSearch`: Performs a comprehensive search for products.
 * - `PLGetProductById`: Fetches a product by its ID for PL.
 *
 * Each endpoint includes:
 * - `query`: The query or mutation function to be executed.
 * - `transformResponse`: A function to transform the response data.
 * - `transformErrorResponse`: A function to handle and transform error responses.
 * - `providesTags` and `invalidatesTags`: Tags used for cache invalidation.
 * - `onQueryStarted`: An optional function to handle actions when a query starts.
 */
const productApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getProductBySlug: builder.query<Product, InputGetBySlug>({
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductBySlugQuery,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch product by slug")
                console.error("RTKQ/transformErrorResponse/getProductBySlug", error)
                return error
            }
        }),
        getProductLocations: builder.query<
            LocationProductRelationshipConnection,
            InputGetProductLocations
        >({
            providesTags: ["Products", "ProductLocations"],
            query: (input) => ({
                query: getProductLocationsQuery,
                args: input
            }),
            transformResponse: (
                data: LocationProductRelationshipConnection
            ): LocationProductRelationshipConnection => {

                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch product locations")
                console.error("RTKQ/transformErrorResponse/getProductLocations", error)
                return error
            }
        }),
        getProductById: builder.query<Product, InputGetById>({
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductByIdQuery,
                args: { ...input }
            }),
            transformResponse: (data: Product): Product => {

                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch product by ID")
                console.error("RTKQ/transformErrorResponse/getProductById", error)
                return error
            }
        }),
        getProductByIdBrewInsights: builder.query<Product, InputGetById>({
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductByIdBrewInsights,
                args: { ...input }
            }),
            transformResponse: (data: Product): Product => {

                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch product by ID (Brew Insights)")
                console.error("RTKQ/transformErrorResponse/getProductById", error)
                return error
            }
        }),
        getProductByIdPpAccount: builder.query<Product, InputGetById>({
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductByIdPPAccount,
                args: input
            }),
            transformResponse: (data: Product): Product => {

                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch product by ID (PP Account)")
                console.error("RTKQ/transformErrorResponse/getProductById", error)
                return error
            }
        }),
        getProductsByBrandId: builder.query<Brand, InputGetById>({
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductsByBrandId,
                args: input
            })
        }),
        getAllStyles: builder.query<StyleConnection, InputGetAll>({
            providesTags: ["Products"],
            query: (input) => ({
                query: allStyles,
                args: input
            })
        }),
        createProduct: builder.mutation<Product, CreateProduct>({
            invalidatesTags: ["Products"],
            query: (input) => ({
                query: createProduct,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                toast.success("Product created successfully")
                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to create product")
                console.error("RTKQ/transformErrorResponse/createProduct", error)
                return error
            }
        }),
        createProductPpInventory: builder.mutation<Product, CreateProduct>({
            query: (input) => ({
                query: createProductMutationInventory,
                args: input
            }),
            transformResponse: (data): Product => {
                toast.success("Product created successfully (PP Inventory)")
                return data as Product
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to create product (PP Inventory)")
                console.error("RTKQ/transformErrorResponse/createProduct", error)
                return error
            }
        }),
        updateProduct: builder.mutation<Product, UpdateProduct>({
            invalidatesTags: ["Products"],
            query: (input) => ({
                query: updateProduct,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                toast.success("Product updated successfully")
                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to update product")
                console.error("RTKQ/transformErrorResponse/updateProduct", error)
                return error
            }
        }),
        deleteProduct: builder.mutation<{ id: string }, DeleteProduct>({
            query: (input) => ({
                query: deleteProduct,
                args: input
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                await queryFulfilled
                setTimeout(() => {
                    dispatch(productApi.util.invalidateTags(["Products"]))
                }, 5000)
            }
        }),
        updateProductAvailability: builder.mutation<
            LocationProductRelationshipConnection,
            InputLocationProductRelationship
        >({
            invalidatesTags: ["ProductLocations"],
            query: (input) => ({
                query: updateLocationProductRelationship,
                args: input
            }),
            transformResponse: (data: any): any => {
                toast.success("Availabilty Updated")
                return data as LocationProductRelationship[]
            },
            transformErrorResponse: (error) => {
                toast.error("Availabilty Update Failed")
                console.error("RTKQ/transformErrorResponse/updateProductAvailability", error)
                return error
            }
        }),
        getAllBeerStyles: builder.query<StyleConnection, InputGetAll>({
            query: (input) => ({
                query: getAllBeerStyles,
                args: input
            }),
            transformResponse: (data: StyleConnection): StyleConnection => {

                return data
            },
            transformErrorResponse: (error) => {
                toast.error("Failed to fetch all beer styles")
                console.error("RTKQ/transformErrorResponse/updateProduct", error)
                return error
            }
        }),
        limitedProductSearch: builder.query<
            WildCardTitleSearchConnection,
            LimitedProductSearchInput
        >({
            providesTags: ["Products"],
            query: (input) => ({
                query: searchBplProducts,
                args: {
                    limit: input.limit || 25,
                    typeFilters: [OpenSearchType.Product],
                    order: input.order,
                    orderBy: input.orderBy,
                    nextToken: input.nextToken,
                    search: {
                        wildcardText: input.searchTerm || "",
                        searchFieldsProducts: {
                            searchFieldsProductsWeights: {
                                brandObjectTitleWeight: 55,
                                descriptionWeight: 0,
                                titleWeight: 40
                            },
                            brewTypes: input.brewType ? [input.brewType] : undefined
                        }
                    }
                }
            })
        }),
        productSearch: builder.query<
            CompositeProduct[],
            {
                productTypeFilter?: ProductTypeFilter
                searchTerm?: string
            }
        >({
            providesTags: ["Products"],
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const CHUNK_SIZE = 750
                const input: InputSearchBpl = {
                    limit: CHUNK_SIZE,
                    typeFilters: [OpenSearchType.Product],
                    search: {
                        wildcardText: arg.searchTerm || "",
                        searchFieldsProducts: {
                            searchFieldsProductsWeights: {
                                brandObjectTitleWeight: 5,
                                descriptionWeight: 0,
                                titleWeight: 10
                            }
                        }
                    },
                    nextToken: null
                }

                if (arg.productTypeFilter && arg.productTypeFilter !== ProductTypeFilter.All)
                    Object.assign(input.search, {
                        searchFieldsProducts: {
                            brewTypes: [ProductTypeFilter[arg.productTypeFilter]]
                        }
                    })

                const initialResponse = await baseQuery({
                    query: searchBplProducts,
                    args: { ...input }
                })

                if (initialResponse.error) {
                    console.error("RTKQ/productSearch", initialResponse.error.message)
                    return { data: [] }
                }

                const data = initialResponse.data as WildCardTitleSearchConnection

                const results = [...data.items]
                let index = 750
                const promises = []
                while (index < data.totalResults) {
                    input.nextToken = String(index)
                    input.limit = Math.min(CHUNK_SIZE, 10000 - index)
                    promises.push(
                        baseQuery({
                            query: searchBplProducts,
                            args: { ...input }
                        })
                    )
                    index += CHUNK_SIZE
                }
                results.push(
                    ...(await Promise.all(promises)).flatMap((res) => res.data?.items || [])
                )

                return {
                    data: results as CompositeProduct[]
                }
            }
        }),
        PLGetProductById: builder.query<CompositeProduct, InputGetById>({
            providesTags: ["Products"],
            query: (input) => ({
                query: plGetProductByIdQuery,
                args: { ...input }
            })
        })
    })
})

const {
    useCreateProductMutation,
    useCreateProductPpInventoryMutation,
    useGetProductByIdPpAccountQuery,
    useGetProductByIdQuery,
    useGetProductBySlugQuery,
    useGetProductLocationsQuery,
    useLazyGetProductByIdPpAccountQuery,
    useLazyGetProductByIdQuery,
    useLazyGetProductBySlugQuery,
    useLazyGetProductLocationsQuery,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useGetProductsByBrandIdQuery,
    useGetAllBeerStylesQuery,
    useLimitedProductSearchQuery,
    useProductSearchQuery,
    usePLGetProductByIdQuery
} = productApi

export {
    productApi,
    useCreateProductMutation,
    useCreateProductPpInventoryMutation,
    useDeleteProductMutation,
    useGetAllBeerStylesQuery,
    useGetProductByIdPpAccountQuery,
    useGetProductByIdQuery,
    useGetProductBySlugQuery,
    useGetProductLocationsQuery,
    useGetProductsByBrandIdQuery,
    useLazyGetProductByIdPpAccountQuery,
    useLazyGetProductByIdQuery,
    useLazyGetProductBySlugQuery,
    useLazyGetProductLocationsQuery,
    useLimitedProductSearchQuery,
    usePLGetProductByIdQuery,
    useProductSearchQuery,
    useUpdateProductMutation
}
