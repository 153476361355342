export const getBrewMenuDisplayByIdQuery = /* GraphQL */ `query GetBrewMenuDisplayById($input: inputGetById!) {
  getBrewMenuDisplayById(input: $input) {
    createdBy
    createdDate
    displayTitle
    displayType
    id
    lastUpdatedBy
    cloudinaryAssetId
    backgroundWatermarkAssetId
    promotionalImageAssets {
      cloudinaryAssetId
      cloudinaryAssetFileName
    }
    menu {
      activeDates
      createdBy
      createdDate
      id
      lastUpdatedBy
      locationId
      ppSubId
      status
      title
      updatedDate
      title
      sections {
        id
        title
        updatedDate
        createdBy
        hiddenOnDisplays
        menuItems {
          id
          isVisible
          customProduct {
            id
            title
            description
            subtitle
            productType
            beverage {
              ... on Beer {
                color
                srm
                ibu
                abv
                traits {
                  otherTraits
                }
                style {
                  srmMin
                  srmMax
                  title
                  label
                  slug
                  description
                  id
                }
              }
              ... on Kombucha {
                kombuchaFamily
                abv
                color
                traits {
                  otherTraits
                }
              }
              ... on Cider {
                ciderFamily
                color
                abv
                traits {
                  otherTraits
                }
              }
            }
          }
          menuItemType
          servings {
            isDefault
            oz
            price
            salePrice
            isActive
            pourSize
            servingType
            label
            isDisplayed
          }
          product {
            id
            title
            color
            abv
            description
            servingVessels
            __typename
            primaryFlavorNotes
            brand {
              id
              title
              displayTitle
              address {
                city
                state
              }
              assets {
                id
                alt
                type
                caption
                ... on S3Asset {
                  __typename
                  path
                }
                ... on CloudinaryAsset {
                  __typename
                  cloudinaryId
                }
              }
            }
            ... on Beer {
              srm
              ibu
              style {
                srmMin
                srmMax
                title
                label
                slug
                description
              }
            }
            ... on Kombucha {
              __typename
              kombuchaFamily
              id
              color
              title
            }
            ... on Cider {
              ciderFamily
            }
            assets {
              id
              alt
              type
              caption
              ... on S3Asset {
                __typename
                path
              }
              ... on CloudinaryAsset {
                __typename
                cloudinaryId
              }
            }
          }
          tapSensor {
            id
            tapNumber
            assignedKegs {
              id
              product {
                id
                title
                color
                abv
                assets {
                  __typename
                  id
                  alt
                  type
                  caption
                  ... on S3Asset {
                    path
                  }
                  ... on CloudinaryAsset {
                    cloudinaryId
                  }
                }
                brand {
                  id
                  title
                  displayTitle
                  address {
                    city
                    state
                  }
                  assets {
                    __typename
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                      path
                    }
                    ... on CloudinaryAsset {
                      cloudinaryId
                    }
                  }
                }
                ... on Beer {
                  id
                  abv
                  ibu
                  srm
                  style {
                    title
                    id
                    abvMax
                    abvMin
                    fgMax
                    fgMin
                    ibuMax
                    ibuMin
                    hops
                    malt
                    ogMax
                    ogMin
                    srmMax
                    srmMin
                    yeast
                  }
                  title
                }
                ... on Cider {
                  ciderFamily
                }
                ... on Kombucha {
                  probiotics
                  kombuchaFamily
                  __typename
                }
              }
            }
            tappedKeg {
              id
              currentFluidOz
              initialFluidOz
              defaultServingSize
              servings {
                oz
                price
                isDefault
              }
              product {
                title
                id
                color
                description
                abv
                servingVessels
                __typename
                primaryFlavorNotes
                brand {
                  id
                  title
                  displayTitle
                  address {
                    city
                    state
                  }
                  assets {
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                      __typename
                      path
                    }
                    ... on CloudinaryAsset {
                      __typename
                      cloudinaryId
                    }
                  }
                }
                ... on Beer {
                  srm
                  ibu
                  style {
                    srmMin
                    srmMax
                    title
                    label
                    slug
                    description
                  }
                }
                ... on Kombucha {
                  __typename
                  kombuchaFamily
                  id
                  color
                  title
                }
                ... on Cider {
                  ciderFamily
                }
                assets {
                  id
                  alt
                  type
                  caption
                  ... on S3Asset {
                    __typename
                    path
                  }
                  ... on CloudinaryAsset {
                    __typename
                    cloudinaryId
                  }
                }
              }
            }
          }
        }
      }
    }
    settings {
      allowPagination
      backgroundColor
      colorScheme
      columns
      fallbackImage
      font
      mediaType
      numberOfItemsPerScreen
      orientation
      primaryFontColor
      productImage
      rotationInterval
      screenMode
      secondaryFontColor
      sectionBackgroundColor
      sectionFontColor
      showABV
      showLocation
      showBrewery
      showDescription
      showIBU
      showLogoOnAllPages
      showOnDeck
      showPricing
      showPrimaryFlavorNotes
      showProductName
      showReducedPrice
      showSection
      showServingType
      showSize
      showSRM
      showStyle
      showTapNumber
      showUnknownKegs
      tertiaryFontColor
    }
    slug
    status
    title
    updatedDate
  }
}`
