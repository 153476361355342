export const getBrewMenuItemsByBmSubId = /* GraphQL */ `query GetMenuItemByBMSubID($input: inputGetById!) {
	getMenuItemsByBMSubID(input: $input){
    returnedCount
    nextToken
    items {
        id
        isVisible
        availability {
          available
          bottled
          canned
          cask
          crowler
          growler
          nitro
          onTap
          packaged
        }
        category
        createdBy
        createdDate
        menuItemType
        tapSensor {
            # id
            tapNumber
            tappedKeg{
              id
              product{
                __typename
                title
                abv
                id
                brand {
                  title
                }
                ... on Beer {
                        srm
                        style {
                            title
                            label
                            slug
                            description
                        }
                    }
                    ... on Kombucha {
                        __typename
                        kombuchaFamily
                        id
                        color
                        title
                    }
                    ... on Cider {
                        ciderFamily 
                    }
                    assets {
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            __typename
                            path
                        }
                        ... on CloudinaryAsset {
                            __typename
                            cloudinaryId
                        }
                    }
              }
            }
            assignedKegs{
              product{
                title
                brand {
                  title
                }
              }
            }
        }
        # customProduct {
        #   createdBy
        #   createdDate
        #   id
        #   lastUpdatedBy
        #   productType
        #   subtitle
        #   title
        #   updatedDate
        # }
        glassware
        id
        lastUpdatedBy
        pricingPreset {
          id
          isDefault
          title
        }
        servings {
          isActive
          isDefault
          label
          oz
          price
        }
        tags
        updatedDate
    },

  }
}`
