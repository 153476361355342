import type {
    BrewMenu,
    DeleteItemsResponse,
    DeleteMenuItemsOrSectionsConnection,
    InputBrewMenuItemOrSection,
    InputCreateMenuItem,
    InputCreateMenuSection,
    InputDeleteBmMenuItems,
    InputDeleteMenuItemsOrSections,
    InputGetById,
    InputUpdateMenuItem,
    InputUpdateMenuSection,
    MenuItem,
    MenuItemConnection,
    MenuSection
} from "types"
import { datadogLocalErrorLog } from "../../datadog/datadogErrorLogger"
import { createMenuItem } from "../../mutations/private/brewmenu/createMenuItem"
import { createMenuSectionMutation } from "../../mutations/private/brewmenu/createMenuSectionMutation"
import { deleteMenuItems } from "../../mutations/private/brewmenu/deleteMenuItems"
import { deleteMenuItemsOrSections } from "../../mutations/private/brewmenu/deleteMenuItemsOrSections"
import { removeMenuItemOrSectionFromBrewMenuMutation } from "../../mutations/private/brewmenu/removeMenuItemOrSectionFromBrewMenuMutation"
import { updateMenuItem } from "../../mutations/private/brewmenu/updateMenuItem"
import { updateMenuSectionMutation } from "../../mutations/private/brewmenu/updateMenuSectionMutation"
import { getBrewMenuItemsByBmSubId } from "../../queries/private/brewmenu/getBrewMenuItemsByBmSubId"
import { baseApi } from "../baseApi"

const brewMenuMenuItemAndSectionApiLog = datadogLocalErrorLog("brewMenuMenuItemAndSectionApi")

const brewMenuMenuItemAndSectionApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrewMenuDisplayBySlugs  */
        getBrewMenuItemsByBMSubId: builder.query<MenuItemConnection, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Menus"],
            query: (input) => ({
                query: getBrewMenuItemsByBmSubId,
                args: input
            }),
            transformResponse: (data: MenuItemConnection): MenuItemConnection => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data as MenuItemConnection
            },
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog("getBrewMenuItemsByBMSubId")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createMenuItem */
        createMenuItem: builder.mutation<MenuItem, InputCreateMenuItem>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: createMenuItem,
                args: input
            }),
            transformResponse: (data: MenuItem): MenuItem => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog("createMenuItem")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createMenuSection */
        createMenuSection: builder.mutation<
            { id: MenuSection["id"]; title: MenuSection["title"] },
            InputCreateMenuSection & { menuId: BrewMenu["id"] }
        >({
            query: (input) => ({
                query: createMenuSectionMutation,
                args: {
                    menuItemIds: [],
                    bmSubId: input.bmSubId,
                    title: input.title
                }
            }),
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog("createMenuSection")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteMenuItemsOrSections */
        deleteMenuItemsOrSections: builder.mutation<
            DeleteMenuItemsOrSectionsConnection,
            InputDeleteMenuItemsOrSections
        >({
            query: (input) => ({
                query: deleteMenuItemsOrSections,
                args: input
            }),
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog("deleteMenuItemsOrSections"),
            invalidatesTags: (result) => ["MenuById", "BrewMenuSubscription", "Menus"]
        }),
        /* https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteMenuItems */
        deleteMenuItems: builder.mutation<DeleteItemsResponse, InputDeleteBmMenuItems>({
            query: (input) => ({
                query: deleteMenuItems,
                args: input
            }),
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog("deleteMenuItems"),
            invalidatesTags: (result) => ["MenuById", "BrewMenuSubscription", "Menus"]
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/removeMenuItemOrSectionFromBrewMenu */
        removeMenuItemOrSectionFromBrewMenu: builder.mutation<
            {
                id: BrewMenu["id"]
                lastUpdatedBy: BrewMenu["lastUpdatedBy"]
                status: BrewMenu["status"]
                title: BrewMenu["title"]
                activeDates: BrewMenu["activeDates"]
                locationId: BrewMenu["locationId"]
            },
            InputBrewMenuItemOrSection
        >({
            query: (input) => ({
                query: removeMenuItemOrSectionFromBrewMenuMutation,
                args: input
            }),
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog(
                "removeMenuItemOrSectionFromBrewMenu"
            )
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateMenuItem */
        updateMenuItem: builder.mutation<MenuItem, InputUpdateMenuItem>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: updateMenuItem,
                args: input
            }),
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog("updateMenuItem"),
            transformResponse: (data: MenuItem): MenuItem => {
                // TODO: Add notifications
                //! NEEDS: brewMenuActions
                return data
            },
            invalidatesTags: (result) => [
                { type: "MenuById", id: "LIST" },
                { type: "MenuById", id: result.id },
                "BrewMenuSubscription",
                "Menus"
            ]
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateMenuSection */
        updateMenuSection: builder.mutation<MenuItem, InputUpdateMenuSection>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: updateMenuSectionMutation,
                args: input
            }),
            transformErrorResponse: brewMenuMenuItemAndSectionApiLog("updateMenuSection"),
            invalidatesTags: [{ type: "MenuById", id: "LIST" }, "Menus"]
        })
    })
})

const {
    useCreateMenuItemMutation,
    useCreateMenuSectionMutation,
    useDeleteMenuItemsOrSectionsMutation,
    useDeleteMenuItemsMutation,
    useRemoveMenuItemOrSectionFromBrewMenuMutation,
    useUpdateMenuItemMutation,
    useUpdateMenuSectionMutation
} = brewMenuMenuItemAndSectionApi

export {
    brewMenuMenuItemAndSectionApi,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createMenuItem */
    useCreateMenuItemMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createMenuSection */
    useCreateMenuSectionMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteMenuItems */
    useDeleteMenuItemsMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteMenuItemsOrSections */
    useDeleteMenuItemsOrSectionsMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/removeMenuItemOrSectionFromBrewMenu */
    useRemoveMenuItemOrSectionFromBrewMenuMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateMenuItem */
    useUpdateMenuItemMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateMenuSection */
    useUpdateMenuSectionMutation
}
