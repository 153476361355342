export const getTapSensorsForBrewMenu = /* GraphQL */ `query GetTapSensorsForBrewMenu($input: inputGetById!) {
    getTapSensorsForBrewMenu(input: $input) {
      returnedCount
      nextToken
      items {
        __typename
        assignedKegs {
          product {
            title
            brand {
              title
            }
          }
        }
        createdBy
        createdDate
        description
        id
        lastSensorUpdateDate
        lastUpdatedBy
        model
        position
        ppSubId
        tapNumber
        tappedKeg {
          id
          product {
            __typename
            title
            abv
            id
            brand {
              title
            }
            ... on Beer {
              srm
              style {
                title
                label
                slug
                description
              }
            }
            ... on Kombucha {
              __typename
              kombuchaFamily
              id
              color
              title
            }
            ... on Cider {
              ciderFamily
            }
            assets {
              id
              alt
              type
              caption
              ... on S3Asset {
                __typename
                path
              }
              ... on CloudinaryAsset {
                __typename
                cloudinaryId
              }
            }
          }
        }
        title
        updatedDate
      }
    }
  }`
