export const updateOrganizationGroupMutation = `
mutation UpdateOrganizationGroup($input: inputUpdateOrganizationGroup!) {
	updateOrganizationGroup(input: $input){
		title
		slug
		id
		bmSubId
		brandId
		cloudinaryAssetId
		ppSubId
	}
}`
