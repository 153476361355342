"use client"

import { useMemo, useState } from 'react'
import { getAPriorDate } from '../utilities/getAPriorDate'

interface UseDateRangeProps {
    timezone?: string
    defaultDayRange?: number
}

export const useDateRange = ({ timezone, defaultDayRange = 7 }: UseDateRangeProps) => {
    const initialDates = useMemo(() => {
        const endDate = getAPriorDate({ number: 1, timezone }).toJSDate()
        const startDate = getAPriorDate({ number: defaultDayRange + 1, timezone }).toJSDate()
        return [endDate, startDate]
    }, [timezone, defaultDayRange])

    const [selectedDates, setSelectedDates] = useState<Date[]>(initialDates)
    const [endDate, startDate] = selectedDates

    return {
        selectedDates,
        setSelectedDates,
        startDate,
        endDate,
        maxDate: initialDates[0]
    }
} 