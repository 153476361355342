export const getAuthenticatedUser = /* GraphQL */ `
query GetAuthenticatedUser {
  getAuthenticatedUser {
	__typename
    id
    givenName
    familyName
    brewlogixSuperadmin
    trustedUser
    bdbAccess
	bmSubscriptions {
		bmSubId
		createdBy
		createdDate
		lastUpdatedBy
		role
		updatedDate
	}
	description
    contact {
      email
      phone
    }
	address {
		street
		street2
		city
		state
		postalCode
		country
	}
    contactPreference
	jobTitle
    mmbBrands {
		brandId
		createdBy
		createdDate
		lastUpdatedBy
		role
		updatedDate
	}
	ppSubscriptions {
		createdBy
		createdDate
		lastUpdatedBy
		ppSubId
		role
		updatedDate
	}
	organization {
      organizationId
      role
      createdDate
    }
    defaultSubscription
	registrationSource
  }
}`
