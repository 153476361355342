// MMB - From useDashBoardStore.ts

export const brandByIdQueryDashboard = /* GraphQL */ `
  query GetBrandById($input: inputGetById!) {
    getBrandById(input: $input) {
      __typename
      yearEstablished
      id
      slug
      title
      displayTitle
      description
      contact {
        phone
        email
      }
      featuredBrew {
        id
        title
        slug
      }
      website
      socialMedia {
        platform
        handle
      }
      yearEstablished
      traits {
        brandStatus
        brandTypes
        brewTypes
        isIndependent
        isContract
      }
      address {
        street
        street2
        city
        state
        country
        postalCode
      }
      assets {
        __typename
        id
        alt
        ... on S3Asset {
          path
        }
        ... on CloudinaryAsset {
            cloudinaryId
        }
	    }
      locations {
        id
        slug
        title
        traits {
          status
        }
      }
      products {
        __typename
        id
        slug
        title
        brand {
          title
          displayTitle
        }
      }
    }
  }
`
