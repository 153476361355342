import { useGetVesselsQuery } from "../../api"

export const useVessels = () => {
    const { data, isLoading } = useGetVesselsQuery()

    return {
        vessels: data || [],
        isLoading
    }
}
