import { gql } from "graphql-request"

export const getBasicOrganizationByIdQuery = gql`
query GetOrganizationById($input: inputGetOrganizationById!) {
	getOrganizationById(input: $input){
        
		id
		
		brands {
			address {
                city
                state
            }
			description
			displayTitle
			id
			locations { 
                id
                title
            }
			products { 
                id
                title
            }
			slug
			title

		}
		brewMenuSubscriptions {
			brandId
			id
			ppSubId
			slug
			subscriptionTier
			title
		}
		contact {
			email
			phone
		}
		externalId
		
		ppSubscriptions {
			
			id
			locationType
			numberOfTapSensors
			slug
			tapSensors { 
                id
            }
			title
		}
		logo {
			updatedDate
			lastUpdatedBy
			id
			createdDate
			createdBy
			cloudinaryId
		
			shared
			tags
		}
		cloudinaryAssetId
		slug
		title
	
    }
}
`
