import type { Location, UpdateLocation } from "types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

export interface ILocationState {
    locationId: string
    location: Location
    locationFormData: UpdateLocation
}

export enum LocationFormFields {
    Title = "title",
    Traits_Type = "traits.type",
    Traits_IsProductionFacility = "traits.isProductionFacility",
    Address_Street = "address.street",
    Address_Street2 = "address.street2",
    Address_City = "address.city",
    Address_State = "address.state",
    Address_PostalCode = "address.postalCode",
    Address_Country = "address.country",
    Traits_IsPrimary = "traits.isPrimary",
    Description = "description",
    Traits_Status = "traits.status",
    Year_Opened = "yearOpened",
    Year_Closed = "yearClosed",
    Traits_IsOpenToPublic = "traits.isOpenToPublic",
    Timezone = "timezone",
    Hours = "hours",
    Contact_Phone = "contact.phone",
    Contact_Email = "contact.email",
    Traits_ToursAvailable = "traits.tourInfo[0].toursAvailable",
    Traits_TourCost = "traits.tourInfo[0].cost",
    Traits_TourHours = "traits.tourInfo[0].hours",
    Traits_TourDescription = "traits.tourInfo[0].description",
    Traits_TourMustBeDrinkingAge = "traits.tourInfo[0].mustBeLegalDrinkingAge",
    Assets = "assets",
    Traits_BrewOptions = "traits.brewOptions",
    Traits_FoodOptions = "traits.foodOptions",
    Traits_SeatingOptions = "traits.seatingOptions",
    Traits_PatronOptions = "traits.patronOptions",
    Traits_PhysicalActivity = "traits.physicalActivity",
    Traits_GameActivity = "traits.gameActivity",
    Traits_EducationalActivity = "traits.educationalActivity",
    Traits_EntertainmentActivity = "traits.entertainmentActivity",
    Traits_MiscActivity = "traits.miscActivity",
    LocationHighlights = "locationHighlights",
    Traits_NearbyAttractions = "traits.nearbyAttractions",
    ProductRelationships = "productRelationships"
}

export interface ILocationActions {
    setLocationId: ({ locationId }: { locationId: string }) => void
    setLocationFormData: ({ locationFormData }: { locationFormData: UpdateLocation }) => void
    resetLocationState: () => void
    populateLocationState: ({ location }: { location?: Location }) => void
}

const initialValues: ILocationState = {
    locationId: "",
    location: null,
    locationFormData: null
}

export const locationState = create<ILocationState & ILocationActions>()(
    devtools(
        (set, get) => ({
            ...initialValues,
            setLocationId: ({ locationId }) => {
                set({ locationId })
            },
            setLocationFormData: ({ locationFormData }) => {
                set({ locationFormData })
            },
            populateLocationState: ({ location }) => {
                set({
                    location,
                    locationId: location?.id
                })
            },
            resetLocationState: () => {
                set({ ...initialValues })
            }
        }),
        { name: "locationState" }
    )
)

export const locationActions = {
    getLocation: locationState.getState().location,
    setLocation: locationState.getState().setLocationId,
    setLocationFormData: locationState.getState().setLocationFormData,
    populateLocationState: locationState.getState().populateLocationState,
    resetLocationState: locationState.getState().resetLocationState
}
