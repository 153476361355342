import { tapSensorFragment } from "utilities"

export const getTapSensorsFromPPSub = /* GraphQL */ `
    query getPPSubById($input: inputGetByPPSubId!) {
        getPPSubById(input: $input) {
            id
            title
            numberOfTapSensors
            tapSensors {
                ...${tapSensorFragment}
            }
           
        }
    }
`
