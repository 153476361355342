import type {
    InputCreateOrganizationGroup,
    InputDeleteOrganizationGroup,
    InputGetOrganizationGroups,
    InputUpdateOrganizationGroup,
    OrganizationGroup
} from "types"
import { baseApi } from "../../../api/baseApi"
import { datadogLocalErrorLog } from "../../../datadog/datadogErrorLogger"
import { createOrganizationGroupMutation } from "../mutations/createOrganizationGroupMutation"
import { deleteOrganizationGroupMutation } from "../mutations/deleteOrganizationGroupMutation"
import { updateOrganizationGroupMutation } from "../mutations/updateOrganizationGroupMutation"
import { getMyOrganizationGroupsQuery } from "../queries/getMyOrganizationGroupsQuery"
import { getOrganizationGroupsQuery } from "../queries/getOrganizationGroupsQuery"

const orgGroupsApiLog = datadogLocalErrorLog("orgGroupsApiLog")

const orgGroupsApi = baseApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        getMyOrganizationGroups: builder.query<OrganizationGroup[], void>({
            query: () => ({
                query: getMyOrganizationGroupsQuery
            }),
            transformErrorResponse: orgGroupsApiLog("getOrganizationGroups")
        }),
        getOrganizationGroups: builder.query<OrganizationGroup[], InputGetOrganizationGroups>({
            providesTags: ["OrganizationGroups"],
            query: (input) => ({
                query: getOrganizationGroupsQuery,
                args: input
            }),
            transformErrorResponse: orgGroupsApiLog("getOrganizationGroups")
        }),
        createOrgGroup: builder.mutation<OrganizationGroup, InputCreateOrganizationGroup>({
            invalidatesTags: ["OrganizationGroups"],
            query: (input) => ({
                query: createOrganizationGroupMutation,
                args: input
            }),
            transformErrorResponse: orgGroupsApiLog("createOrgGroup")
        }),
        updateOrgGroup: builder.mutation<OrganizationGroup, InputUpdateOrganizationGroup>({
            invalidatesTags: ["OrganizationGroups"],
            query: (input) => ({
                query: updateOrganizationGroupMutation,
                args: input
            }),
            transformErrorResponse: orgGroupsApiLog("updateOrgGroup")
        }),
        deleteOrgGroup: builder.mutation<OrganizationGroup, InputDeleteOrganizationGroup>({
            invalidatesTags: ["OrganizationGroups"],
            query: (input) => ({
                query: deleteOrganizationGroupMutation,
                args: input
            }),
            transformErrorResponse: orgGroupsApiLog("deleteOrgGroup")
        })
    })
})

const {
    useGetMyOrganizationGroupsQuery,
    useGetOrganizationGroupsQuery,
    useCreateOrgGroupMutation,
    useUpdateOrgGroupMutation,
    useDeleteOrgGroupMutation
} = orgGroupsApi

export {
    orgGroupsApi,
    useGetMyOrganizationGroupsQuery,
    useGetOrganizationGroupsQuery,
    useCreateOrgGroupMutation,
    useUpdateOrgGroupMutation,
    useDeleteOrgGroupMutation
}
