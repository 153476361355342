import type { InputAddress, Organization } from "types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

export interface IWelcomeOrgFormData {
    address?: InputAddress
    feature?: any
    title?: string
    cloudinaryAssetId?: string
}

interface ICloudinary {
    createUploadWidget?: () => void
    open?: () => void
    close?: () => void
}
export interface IOrganizationState {
    organizationId: string
    org: Organization
    welcomeOrgFormData: IWelcomeOrgFormData
    feature: any
    showWelcomeMediaUploadModal: boolean
    welcomeOrgFormCloudinaryWidget: ICloudinary
    activeOrgFormMedia: string
    createdWelcomeOrgData: Organization
}

export interface IOrganizationActions {
    setOrganizationId: ({ organizationId }: { organizationId: string }) => void
    setWelcomeOrgFormData: ({
        welcomeOrgFormData
    }: {
        welcomeOrgFormData: IWelcomeOrgFormData
    }) => void
    resetOrganizationState: () => void
    populateOrganizationState: ({ org }: { org?: Organization }) => void
    setFeature: (feature: any) => void
    setShowWelcomeMediaUploadModal: (show: boolean) => void
    setWelcomeOrgFormCloudinaryWidget: (cloudinaryWidget: unknown) => void
    setActiveOrgFormMedia: (activeOrgFormMedia: string) => void
    setCreatedWelcomeOrgData: (createdWelcomeOrgData: Organization) => void
}

const initialValues: IOrganizationState = {
    organizationId: "",
    org: null,
    welcomeOrgFormData: null,
    feature: null,
    showWelcomeMediaUploadModal: false,
    welcomeOrgFormCloudinaryWidget: null,
    activeOrgFormMedia: null,
    createdWelcomeOrgData: null
}

export const organizationState = create<IOrganizationState & IOrganizationActions>()(
    devtools(
        (set, get) => ({
            ...initialValues,
            setFeature: (feature) => set({ feature }),
            showWelcomeMediaUploadModal: false,
            setShowWelcomeMediaUploadModal: (showWelcomeMediaUploadModal) =>
                set({ showWelcomeMediaUploadModal }),
            setWelcomeOrgFormCloudinaryWidget: (welcomeOrgFormCloudinaryWidget) =>
                set({ welcomeOrgFormCloudinaryWidget }),
            setOrganizationId: ({ organizationId }) => set({ organizationId }),
            setWelcomeOrgFormData: ({ welcomeOrgFormData }) => set({ welcomeOrgFormData }),
            setActiveOrgFormMedia: (activeOrgFormMedia) => set({ activeOrgFormMedia }),
            setCreatedWelcomeOrgData: (createdWelcomeOrgData) => set({ createdWelcomeOrgData }),
            populateOrganizationState: ({ org }) =>
                set({
                    org,
                    organizationId: org?.id,
                    welcomeOrgFormData: {
                        address: {
                            street: org?.address?.street,
                            street2: org?.address?.street2,
                            city: org?.address?.city,
                            state: org?.address?.state,
                            postalCode: org?.address?.postalCode,
                            country: org?.address?.country
                        },
                        title: org?.title
                    }
                }),
            resetOrganizationState: () => set({ ...initialValues })
        }),
        { name: "brandState" }
    )
)

export const organizationActions = {
    getOrganization: organizationState.getState().org,
    setOrganization: organizationState.getState().setOrganizationId,
    setOrganizationFormData: organizationState.getState().setWelcomeOrgFormData,
    populateOrganizationState: organizationState.getState().populateOrganizationState,
    resetOrganizationState: organizationState.getState().resetOrganizationState
}
