"use client"
import type { Keg } from "types"
import { useGetTappedKegsQuery } from "../../../api/inventoryApi"
import { selectActivePpSubId } from "../../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../../store"
import { type InventoryState, inventoryActions, inventoryState } from "../inventoryState"

export interface UseTappedKegsHook extends Partial<InventoryState> {
    isFetching: boolean
    isLoading: boolean
    isSuccessTappedKegs: boolean
    isError: boolean
    tappedKegs: Keg[]
}

export const useTappedKegs = (refetchOnMountOrArgChange: boolean = true): UseTappedKegsHook => {
    const selectedKeg = inventoryState.use.selectedKeg()
    const selectKeg = inventoryActions.selectKeg

    const ppSubId = useAppSelector(selectActivePpSubId)

    const {
        data: tappedKegs,
        isLoading,
        isError,
        isFetching,
        isSuccess
    } = useGetTappedKegsQuery({ ppSubId }, { skip: !ppSubId, refetchOnMountOrArgChange })

    return {
        tappedKegs,
        selectKeg,
        selectedKeg,
        isLoading,
        isSuccessTappedKegs: isSuccess,
        isError,
        isFetching
    }
}
