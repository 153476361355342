import {
    type BrewMenu,
    BrewMenuStatus,
    type InputCreateBrewMenu,
    type InputDeleteBrewMenu,
    type InputUpdateBrewMenu
} from "types"
import {
    useAddMenuItemOrSectionToBrewMenuMutation,
    useCreateBrewMenuMutation,
    useDeleteBrewMenuMutation,
    useUpdateBrewMenuMutation
} from "../../../api/brewMenu/brewMenuMenuApi"

export const useBrewMenuMenuMutations = (bmSubId: string) => {
    const [createMenuMutation] = useCreateBrewMenuMutation()
    const [deleteMenuMutation] = useDeleteBrewMenuMutation()
    const [updateMenuMutation] = useUpdateBrewMenuMutation()
    const [addMenuItemOrSectionMutation] = useAddMenuItemOrSectionToBrewMenuMutation()

    const createMenu = async (title: InputCreateBrewMenu["title"]): Promise<BrewMenu> => {
        // @ts-ignore
        const { data } = await createMenuMutation({
            bmSubId,
            status: BrewMenuStatus.Draft,
            title
        })

        if (data) return data
    }

    const deleteMenu = async (brewMenuId: InputDeleteBrewMenu["brewMenuId"]) => {
        await deleteMenuMutation({
            bmSubId,
            brewMenuId
        })
    }

    const updateMenu = async (input: {
        brewMenuId: InputUpdateBrewMenu["brewMenuId"]
        status?: InputUpdateBrewMenu["status"]
        title?: InputUpdateBrewMenu["title"]
        sectionIds?: InputUpdateBrewMenu["sectionIds"]
    }): Promise<BrewMenu> => {
        // @ts-ignore
        const { data } = await updateMenuMutation({
            bmSubId,
            ...input
        })
        if (data) return data
    }

    return {
        createMenu,
        deleteMenu,
        updateMenu
    }
}
