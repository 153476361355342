import { skipToken } from "@reduxjs/toolkit/query"
import { useGetBrewMenusByBMSubIdQuery } from "../../../api/brewMenu/brewMenuMenuApi"
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken"

export const useBrewMenuDisplayMenuList = (bmSubId: string) => {
    const { data, isError, isFetching, isLoading, isSuccess } = useGetBrewMenusByBMSubIdQuery(
        brewMenuSubscriptionToken(bmSubId) ?? skipToken
    )

    return {
        menuList: data?.items || [],
        isErrorBrewMenuDisplayMenuList: isError,
        isFetchingBrewMenuDisplayMenuList: isFetching,
        isLoadingBrewMenuDisplayMenuList: isLoading,
        isSuccessBrewMenuDisplayMenuList: isSuccess
    }
}
