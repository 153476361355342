import { useGetVesselByIdQuery } from "../../api"

export const useVessel = (id: string) => {
    const { data, isLoading } = useGetVesselByIdQuery({ itemId: id })

    return {
        vessel: data,
        isLoading
    }
}
