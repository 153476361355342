"use client"

import React from "react"

export const DatadogUserContext = React.createContext(null)

export const DatadogUserProvider = ({ children }) => {
    const user = React.useRef<string>("")

    const getUser = React.useCallback((): string => {
        return user.current || ""
    }, [])

    const setUser = React.useCallback((userId: string) => {
        user.current = userId
    }, [])

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = React.useMemo(() => {
        return { getUser, setUser }
    }, [getUser, setUser])

    return (
        <DatadogUserContext.Provider value={contextValue}>{children}</DatadogUserContext.Provider>
    )
}
