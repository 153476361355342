export const superAdminUpdatePPSubMutation = /* GraphQL */ `
mutation SuperAdminUpdatePPSub($input: inputSuperAdminUpdatePPSub!) {
	superAdminUpdatePPSub(input: $input){
		address {
			city
			country
			# geo { ...geo }
			postalCode
			state
			street
			street2
		}
		brewMenuTier
		createdBy
		createdDate
		expirationDate
		gateways {
			channel
			connectionStatus
			createdBy
			createdDate
			digiDeviceId
			firmware
			firmwareStatus
			gatewayDeviceId
			health
			id
			installedDate
			ip
			lastConnect
			lastContactDate
			lastDisconnect
			lastUpdatedBy
			model
			pairingDelay
			panId
			# performancePlatformSubscription { ...performancePlatformSubscription }
			serviceWindow
			sleepTime
			status
			# tapSensors { ...tapSensors }
			title
			type
			updatedDate
			upgradeTimeout
		}
		hubSpotId
		id
		lastUpdatedBy
		locationType
		numberOfTapSensors
		onlyPriorityBrands
		organization {
			# address { ...address }
			# billing { ...billing }
			# brands { ...brands }
			# brewMenuSubscriptions { ...brewMenuSubscriptions }
			# contact { ...contact }
			createdBy
			createdDate
			externalId
			hubspotId
			id
			lastUpdatedBy
			# logo { ...logo }
			# mediaLibrary { ...mediaLibrary }
			# owner { ...owner }
			# ppSubscriptions { ...ppSubscriptions }
			slug
			status
			title
			updatedDate
			# users { ...users }
		}
		priorityBrands {
			# address { ...address }
			# assets { ...assets }
			baId
			# contact { ...contact }
			createdBy
			createdDate
			description
			displayTitle
			# featuredBrew { ...featuredBrew }
			guid
			id
			lastUpdatedBy
			# locations { ...locations }
			# mediaLibrary { ...mediaLibrary }
			# organization { ...organization }
			# products { ...products }
			slug
			# socialMedia { ...socialMedia }
			subtitle
			title
			# traits { ...traits }
			updatedDate
			website
			yearEstablished
		}
		settings {
			batteryReplacementPlan
			coolers
			couplers
			daysOnHandGoal
			defaultServingSize
			freshnessThreshold
			inventoryTurnsGoal
			isActive
			newProductDefinition
			pricingMethod
			pricingPercentage
			productLifeTracking
			seatingCapacity
			servingSizes
			# standardHours { ...standardHours }
			tappedGoal
			timeZone
			varietyScoreGoal
			workWeekStartDay
		}
		slug
		tapSensors {
			# assignedKegs { ...assignedKegs }
			barTitle
			connectionStatus
			createdBy
			createdDate
			description
			firmware
			gatewayDeviceId
			gatewayId
			id
			lastSensorUpdateDate
			lastUpdatedBy
			lastValidRawReading
			model
			# performancePlatformSubscription { ...performancePlatformSubscription }
			position
			ppSubId
			rawReading
			sensorDeviceId
			signalStrength
			status
			tapNumber
			# tappedKeg { ...tappedKeg }
			title
			transformedReading
			updatedDate
			voltage
		}
		title
		updatedDate
	}
}`
