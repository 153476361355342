const searchBplProducts = /* GraphQL */ `
query SearchBPL($input: inputSearchBPL!) {
    searchBPL(input: $input) {  
        nextToken
        returnedCount
        totalResults
        items {
            __typename
            id
            slug
            title
            updatedDate
            createdDate
            description
            ... on Product {
                isVerified
                abv
                assets {
                    __typename
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                        path
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
                brand {
                    id
                    title
                    displayTitle
                    slug
                    assets {
                        __typename
                        id
                        alt
                        type
                        caption
                        ... on S3Asset {
                            path
                        }
                        ... on CloudinaryAsset {
                            cloudinaryId
                        }
                    }
                    address {
                        street
                        city
                        state
                    }
                }   
                traits {
                    availability
                    allergens 
                    otherTraits
                }
               
                isNewProduct
                hintsOfFlavorNotes
                tastingExperience
                primaryFlavorNotes
                servingVessels
                servingTemperature
                ingredients {
                    title
                    type
                }
                locationRelationships {
                    onTap
                    available
                    location {
                        productsAvailable {
                            onTap
                        }    
                    }    
                }
                locationsAvailable {
                    onTap
                    available
                }    
                foodPairingList {
                    category
                    title
                }
                ... on Beer {
                    srm
                    color
                    ibu
                    style {
                        blxGroup
                        servingVessels
                        title
                        category {
                            title
                        }
                        foodPairingList{
                            category
                            title
                        }
                        abvMax
                        abvMin
                        ibuMax
                        ibuMin
                        id
                        label
                        slug
                        srmMax
                        srmMin
                        title
                    }
                }
                ... on Kombucha {
                    kombuchaFamily
                    appearance
                    body
                    acidity
                    brixCount
                    carbonation
                    probiotics
                    perceivedSweetness
                    sourness
                    teaCharacter
                    color
                }
                ... on Cider {
                    ciderFamily
                    acidity
                    brixCount
                    carbonation
                    perceivedSweetness
                    tannin
                    color
                }
                ... on Unverified {
                    ppSubId
                    brandName
                    color
                }
            }            
        }
    }
}
`

export default searchBplProducts
