import { toast } from "sonner"
import type { InputCreateAccountRequest } from "types"
import createAccountRequestMutation from "../mutations/private/account/createAccountRequestMutation"
import { baseApi } from "./baseApi"

const accountApi = baseApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        createAccountRequest: builder.mutation<void, InputCreateAccountRequest>({
            query: (input) => ({
                query: createAccountRequestMutation,
                args: input
            }),
            transformErrorResponse: () => toast.error("Request Failed"),
            transformResponse: () => {
                toast.success("Request Created")
            }
        })
    })
})

const { useCreateAccountRequestMutation } = accountApi

export { accountApi, useCreateAccountRequestMutation }
