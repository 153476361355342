export const updateKegMutation = /* GraphQL */ `mutation updateKeg ($input: updateKeg!) {
    updateKeg(input: $input) {
      #id
      cooler
      coupler
      kegStatus
      cost
      initialFluidOz
      currentFluidOz
      servingSizes
      defaultServingSize
      product {
        __typename
        id
        title
        ... on Beer{
          abv
          srm
          style {
            id
            title
           }
        }
      }
      vessel {
        id
        title
        slug
      }
    }
  }`
