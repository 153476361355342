import { toast } from "sonner"
import type {
    Asset,
    InputAssetIds,
    InputCreateAsset,
    InputGetMediaLibrary,
    InputUpdateAsset,
    MediaLibrary
} from "types"
import { createAssetMutation } from "../mutations/private/asset/createAssetMutation"
import { deleteAssetMutation } from "../mutations/private/asset/deleteAssetMutation"
import { updateAssetMutation } from "../mutations/private/asset/updateAssetMutation"
import { getMediaLibraryQuery } from "../queries/private/media/getMediaLibraryQuery"
import { mediaActions } from "../state"
import { baseApi } from "./baseApi"

/**
 * The `mediaApi` object provides endpoints for interacting with the media library.
 * It includes queries and mutations for fetching, creating, deleting, and updating media assets.
 *
 * Endpoints:
 * - `getMediaLibrary`: Fetches the media library.
 *   - Provides tags: ["MediaLibrary", "Brand", "Brands"]
 *   - Transforms the response to set the media library in the state.
 *   - Displays an error toast on failure.
 *
 * - `createAsset`: Creates a new media asset.
 *   - Invalidates tags: ["MediaLibrary", "Brand", "Brands"]
 *   - Displays a success toast on successful upload.
 *   - Logs an error on failure.
 *
 * - `deleteAsset`: Deletes a media asset.
 *   - Invalidates tags: ["MediaLibrary", "Brand", "Brands"]
 *   - Displays a success toast on successful deletion.
 *   - Logs an error on failure.
 *
 * - `updateAsset`: Updates an existing media asset.
 *   - Invalidates tags: ["MediaLibrary"]
 *   - Displays a success toast on successful update.
 *   - Logs an error on failure.
 */
const mediaApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getMediaLibrary: builder.query<MediaLibrary, InputGetMediaLibrary>({
            providesTags: ["MediaLibrary", "Brand", "Brands"],
            query: (input) => ({
                query: getMediaLibraryQuery,
                args: input
            }),
            transformResponse: (data: MediaLibrary): MediaLibrary => {
                mediaActions.setMediaLibrary(data)
                return data
            },
            transformErrorResponse: (error) => {
                toast.error("There was an error getting the media library.")
                console.error("RTKQ/transformErrorResponse/getMediaLibrary", error)
                return error
            }
        }),
        createAsset: builder.mutation<Asset, InputCreateAsset>({
            query: (input) => ({
                query: createAssetMutation,
                args: input
            }),
            invalidatesTags: ["MediaLibrary", "Brand", "Brands"],
            transformResponse: (data: Asset): Asset => {
                toast.success("Asset Uploaded")
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/createAsset", error)
            }
        }),
        deleteAsset: builder.mutation<Asset, InputAssetIds>({
            query: (input) => ({
                query: deleteAssetMutation,
                args: input
            }),
            invalidatesTags: ["MediaLibrary", "Brand", "Brands"],
            transformResponse: (data: Asset): Asset => {
                toast.success("Asset Deleted")
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/deleteAsset", error)
            }
        }),
        updateAsset: builder.mutation<Asset, InputUpdateAsset>({
            query: (input) => ({
                query: updateAssetMutation,
                args: input
            }),
            invalidatesTags: ["MediaLibrary"],
            transformResponse: (data: Asset): Asset => {
                toast.success("Asset Saved")
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/createAsset", error)
            }
        })
    })
})

const {
    useCreateAssetMutation,
    useGetMediaLibraryQuery,
    useDeleteAssetMutation,
    useUpdateAssetMutation
} = mediaApi

export {
    mediaApi,
    useCreateAssetMutation,
    useDeleteAssetMutation,
    useGetMediaLibraryQuery,
    useUpdateAssetMutation
}
