"use client"
import { DateTime } from "luxon"
import { type InputGetThroughputByPpSub, TimeFrame, TimeZone } from "types"
import { TimeMapping, getBeginingOfDayTimestamp, getEndOfDayTimestamp } from "utilities"
import {
    useGetThroughputDetailsByPPSubQuery,
    useGetThroughputSummaryByPPSubQuery
} from "../../../api"
import { getAPriorDate } from "../../../utilities/getAPriorDate"
import { usePPSub } from "../../ppSubscription"
import { useBrewlogixUser } from "../../user"

export const useThroughputByPpSub = ({
    date,
    days = 7,
    dateRange,
    mode
}: {
    date?: DateTime
    days?: number
    dateRange?: Date[]
    mode?: "details" | "summary"
}) => {
    const { userData } = useBrewlogixUser()
    const { ppSubApiData } = usePPSub()
    const userTimeZone =
        TimeMapping[ppSubApiData?.settings?.timeZone] || TimeMapping[TimeZone.UsEastern]
    let startDate
    let endDate

    if (dateRange && dateRange[0] && dateRange[1]) {
        startDate = getBeginingOfDayTimestamp(
            DateTime.fromJSDate(dateRange[0]).setZone(userTimeZone).toString()
        )
        endDate = getEndOfDayTimestamp(
            DateTime.fromJSDate(dateRange[1]).setZone(userTimeZone).toString()
        )
    } else {
        const selectedDate: DateTime = date
            ? date
            : getAPriorDate({
                  number: 1,
                  timezone: ppSubApiData?.settings?.timeZone
              })

        const daysBeforeSelectedDate: string = selectedDate?.minus({ days: days - 1 }).toString()

        startDate = getBeginingOfDayTimestamp(daysBeforeSelectedDate)
        endDate = getEndOfDayTimestamp(selectedDate?.toString())
    }

    const inputData: InputGetThroughputByPpSub = {
        ppSubId: ppSubApiData?.id,
        dateRange: {
            startDate,
            endDate
        },
        sortAscending: false,
        timeFrame: TimeFrame.Day
    }

    const {
        data: throughputDetails,
        isLoading: isLoadingDetails,
        isFetching: isFetchingDetails,
        isError: isErrorDetails,
        refetch: refetchDetails
    } = useGetThroughputDetailsByPPSubQuery(inputData, {
        skip:
            !userData?.id ||
            !ppSubApiData?.id ||
            (dateRange && (!dateRange[0] || !dateRange[1])) ||
            mode === "summary"
    })

    const {
        data: throughputSummary,
        isLoading: isLoadingSummary,
        isFetching: isFetchingSummary,
        isError: isErrorSummary,
        refetch: refetchSummary
    } = useGetThroughputSummaryByPPSubQuery(inputData, {
        skip:
            !userData?.id ||
            !ppSubApiData?.id ||
            (dateRange && (!dateRange[0] || !dateRange[1])) ||
            mode === "details"
    })

    return {
        throughputDetails,
        isLoadingDetails,
        isFetchingDetails,
        isErrorDetails,
        throughputSummary,
        isLoadingSummary,
        isFetchingSummary,
        isErrorSummary,
        refetch: () => {
            if (mode !== "summary") refetchDetails()
            if (mode !== "details") refetchSummary()
        }
    }
}
