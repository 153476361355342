export const getBrewMenuSubscriptionBySlugPublicQuery = /* GraphQL */ `query GetBrewMenuSubscriptionBySlug($input: inputGetBySlug!) {
	getBrewMenuSubscriptionBySlug(input: $input) {
	  brandId
	  createdBy
	  createdDate
	  displays {
		createdBy
		createdDate
		displayType
		id
		lastUpdatedBy
		status
		title
		updatedDate
		slug
		displayTitle
	  }
	  hubSpotId
	  id
	  isActive
	  lastUpdatedBy
	  locationIds
	  menus {
		activeDates
		createdBy
		createdDate
		id
		lastUpdatedBy
		locationId
		ppSubId
		status
		title
		updatedDate
	  }
	  ppSubId
	  slug
	  subscriptionTier
	  title
	  updatedDate
	}
  }`
