import { gql } from "graphql-request"

export const searchBPLLocations = gql`
query SearchBPL($input: inputSearchBPL!) {
    searchBPL(input: $input) {  
        nextToken
        returnedCount
        totalResults
        items {
            __typename
            id
            slug
            title
            updatedDate
            createdDate
            description
            ... on Location {
                updatedDate
                title
                id
                createdDate
                address {
                    city
                    country
                    postalCode
                    state
                    street
                    street2
                }
                assets {
                    alt
                    bytes
                    caption
                    category
                    createdBy
                    createdDate
                    height
                    id
                    lastUpdatedBy
                    path
                    shared
                    tags
                    type
                    updatedDate
                    width
                }
                brand {
                    baId
                    createdBy
                    createdDate
                    description
                    displayTitle
                    guid
                    id
                    lastUpdatedBy
                    slug
                    subtitle
                    title
                    updatedDate
                    website
                    yearEstablished
                }
                completionPercentage
                contact {
                    email
                    phone
                }
                createdBy
                description
                guid
                hours {
                    day
                    hours
                    isOpen
                }
                lastUpdatedBy
                links {
                    label
                    target
                    url
                }
                locationHighlights {
                    customHeadline
                    description
                    headline
                }
                productRelationships {
                    available
                    canned
                    growler
                    onTap
                }
                productsAvailable {
                    available
                    canned
                    growler
                    onTap
                }
                slug
                subtitle
                # timezone
                traits {
                    brewOptions
                    draftTaps
                    educationalActivity
                    entertainmentActivity
                    foodOptions
                    gameActivity
                    isOpenToPublic
                    isPrimary
                    isProductionFacility
                    miscActivity
                    parkingOptions
                    patronOptions
                    physicalActivity
                    seatingOptions
                    status
                    type
                    website
                }
                yearClosed
                yearOpened
            }            
        }
    }
}
`
