import type { SerializedError } from "@reduxjs/toolkit"
import type { GraphQLError } from "graphql"
import type { CompositeProduct, PerformancePlatformSubscription } from "types"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { productApi } from "../../api"
import { blxQ } from "../store"

export interface IProductsState {
    ProductsId: string
    Products: PerformancePlatformSubscription
}

// export interface IProductsActions {}

// const initialValues: IProductsState = {}

export const ProductsState = create()(
    //<IProductsState & IProductsActions>
    devtools(
        (set) => ({
            // ...initialValues,
        }),
        { name: "ProductsState" }
    )
)

const getProductById = async ({
    productId,
    brandId
}: {
    productId: string
    brandId: string
}): Promise<{
    product: CompositeProduct
    error: GraphQLError | SerializedError
}> => {
    const product = await blxQ(
        productApi.endpoints.getProductByIdBrewInsights.initiate({
            itemId: productId,
            parentId: brandId
        })
    )
    return { product: product?.data, error: product?.error }
}

export const productActions = {
    getProductById
    // setProductsData: ProductsState.getState().setProductsData,
    // populateProductsState: ProductsState.getState().populateProductsState,
    // resetProductsState: ProductsState.getState().resetProductsState,
}
