export const updateBrewMenuSubscription = /* GraphQL */ `mutation UpdateBrewMenuSubscription($input: inputUpdateBrewMenuSubscription!) {
	updateBrewMenuSubscription(input: $input) {
	  updatedDate
	  lastUpdatedBy
	  id
	  createdDate
	  createdBy
	  brandId
	  displays {
		createdBy
		createdDate
		displayTitle
		displayType
		id
		lastUpdatedBy
		slug
		status
		title
		updatedDate
	  }
	  hubSpotId
	  isActive
	  locationIds
	  logo {
		cloudinaryId
		createdBy
		createdDate
		id
		lastUpdatedBy
		shared
		tags
		updatedDate
	  }
	  menus {
		activeDates
		createdBy
		createdDate
		id
		lastUpdatedBy
		locationId
		ppSubId
		sectionIds
		status
		title
		updatedDate
	  }
	  organization {
		createdBy
		createdDate
		externalId
		hubspotId
		id
		lastUpdatedBy
		slug
		status
		title
		updatedDate
	  }
	  ppSubId
	  slug
	  subscriptionTier
	  title
	}
  }`
