export const removeTappedKegMutation = /* GraphQL */ `mutation removeTappedKeg ($input: removeTappedKeg!) {
  removeTappedKeg (input: $input) {
      id
      tapNumber
    #   createdDate
    #   updatedDate
    #   createdBy
    #   lastUpdatedBy
    #   gatewayDeviceId
    #   gatewayId
    #   sensorDeviceId
    #   voltage
    #   barTitle
    #   tappedKeg {
    #       id
    #       createdDate
    #       updatedDate
    #       createdBy
    #       lastUpdatedBy
    #       tappedDate
    #       product {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           guid
    #           title
    #           slug
    #           description
    #           brand {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               guid
    #               slug
    #               title
    #               description
    #               displayTitle
    #               subtitle
    #               website
    #               baId
    #               yearEstablished
    #           }
    #           assets {
    #               id
    #               caption
    #               category
    #               createdBy
    #               lastUpdatedBy
    #               createdDate
    #               updatedDate
    #               tags
    #               shared
    #               path
    #               alt
    #               type
    #               bytes
    #               width
    #               height
    #           }
    #           traits {
    #               availability
    #               allergens
    #               otherTraits
    #           }
    #           releaseDate
    #           servingVessels
    #           servingTemperature
    #           foodPairingList {
    #               category
    #               title
    #           }
    #           ingredients {
    #               type
    #               title
    #           }
    #           primaryFlavorNotes
    #           hintsOfFlavorNotes
    #           isVerified
    #           color
    #           isNewProduct
    #           tastingExperience
    #           locationsAvailable {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               guid
    #               slug
    #               title
    #               subtitle
    #               description
    #               timezone
    #               yearOpened
    #               yearClosed
    #               completionPercentage
    #           }
    #           abv
    #       }
    #       vessel {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           title
    #           slug
    #           label
    #           shortTitle
    #           tareWeight
    #           couplerWeight
    #           totalVolume
    #           suggestedTotalVolume
    #           material
    #           displayOrder
    #           iconSlug
    #           awrWeight
    #           awrSleep
    #       }
    #       receivedDate
    #       expirationDate
    #       coupler
    #       cooler
    #       initialFluidOz
    #       servingSizes
    #       defaultServingSize
    #       actualKickDate
    #       cost
    #       currentFluidOz
    #       projectedKickDate
    #       depletionPace
    #       depletionRate
    #       depletionStats {
    #           projectedKickDate
    #           depletionPace
    #           depletionRate
    #           depletionEstimates {
    #               date
    #               fluidOz
    #           }
    #       }
    #       kegStatus
    #       assignedPositionIndex
    #       isEstimate
    #       isPartial
    #       isTemporary
    #   }
    #   assignedKegs {
    #       id
    #       createdDate
    #       updatedDate
    #       createdBy
    #       lastUpdatedBy
    #       tappedDate
    #       product {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           guid
    #           title
    #           slug
    #           description
    #           brand {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               guid
    #               slug
    #               title
    #               description
    #               displayTitle
    #               subtitle
    #               website
    #               baId
    #               yearEstablished
    #           }
    #           assets {
    #               id
    #               caption
    #               category
    #               createdBy
    #               lastUpdatedBy
    #               createdDate
    #               updatedDate
    #               tags
    #               shared
    #               path
    #               alt
    #               type
    #               bytes
    #               width
    #               height
    #           }
    #           traits {
    #               availability
    #               allergens
    #               otherTraits
    #           }
    #           releaseDate
    #           servingVessels
    #           servingTemperature
    #           foodPairingList {
    #               category
    #               title
    #           }
    #           ingredients {
    #               type
    #               title
    #           }
    #           primaryFlavorNotes
    #           hintsOfFlavorNotes
    #           isVerified
    #           color
    #           isNewProduct
    #           tastingExperience
    #           locationsAvailable {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               guid
    #               slug
    #               title
    #               subtitle
    #               description
    #               timezone
    #               yearOpened
    #               yearClosed
    #               completionPercentage
    #           }
    #           abv
    #       }
    #       vessel {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           title
    #           slug
    #           label
    #           shortTitle
    #           tareWeight
    #           couplerWeight
    #           totalVolume
    #           suggestedTotalVolume
    #           material
    #           displayOrder
    #           iconSlug
    #           awrWeight
    #           awrSleep
    #       }
    #       receivedDate
    #       expirationDate
    #       coupler
    #       cooler
    #       initialFluidOz
    #       servingSizes
    #       defaultServingSize
    #       actualKickDate
    #       cost
    #       currentFluidOz
    #       projectedKickDate
    #       depletionPace
    #       depletionRate
    #       depletionStats {
    #           projectedKickDate
    #           depletionPace
    #           depletionRate
    #           depletionEstimates {
    #               date
    #               fluidOz
    #           }
    #       }
    #       kegStatus
    #       assignedPositionIndex
    #       isEstimate
    #       isPartial
    #       isTemporary
    #   }
    #   title
    #   position
    #   performancePlatformSubscription {
    #       id
    #       createdDate
    #       updatedDate
    #       createdBy
    #       lastUpdatedBy
    #       gateways {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           gatewayDeviceId
    #           ip
    #           installedDate
    #           lastContactDate
    #           tapSensors {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               gatewayDeviceId
    #               gatewayId
    #               sensorDeviceId
    #               voltage
    #               barTitle
    #               tapNumber
    #               title
    #               position
    #               description
    #               model
    #               firmware
    #               connectionStatus
    #               status
    #           }
    #           panId
    #           channel
    #           sleepTime
    #           upgradeTimeout
    #           pairingDelay
    #           performancePlatformSubscription {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               title
    #               slug
    #               numberOfTapSensors
    #               expirationDate
    #               onlyPriorityBrands
    #               locationType
    #           }
    #           title
    #           model
    #           firmware
    #           serviceWindow
    #           health
    #           type
    #           status
    #       }
    #       tapSensors {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           gatewayDeviceId
    #           gatewayId
    #           sensorDeviceId
    #           voltage
    #           barTitle
    #           tapNumber
    #           tappedKeg {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               tappedDate
    #               receivedDate
    #               expirationDate
    #               coupler
    #               cooler
    #               initialFluidOz
    #               servingSizes
    #               defaultServingSize
    #               actualKickDate
    #               cost
    #               currentFluidOz
    #               projectedKickDate
    #               depletionPace
    #               depletionRate
    #               kegStatus
    #               assignedPositionIndex
    #               isEstimate
    #               isPartial
    #               isTemporary
    #           }
    #           assignedKegs {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               tappedDate
    #               receivedDate
    #               expirationDate
    #               coupler
    #               cooler
    #               initialFluidOz
    #               servingSizes
    #               defaultServingSize
    #               actualKickDate
    #               cost
    #               currentFluidOz
    #               projectedKickDate
    #               depletionPace
    #               depletionRate
    #               kegStatus
    #               assignedPositionIndex
    #               isEstimate
    #               isPartial
    #               isTemporary
    #           }
    #           title
    #           position
    #           performancePlatformSubscription {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               title
    #               slug
    #               numberOfTapSensors
    #               expirationDate
    #               onlyPriorityBrands
    #               locationType
    #           }
    #           description
    #           model
    #           firmware
    #           connectionStatus
    #           status
    #       }
    #       kegs {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           tappedDate
    #           product {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               guid
    #               title
    #               slug
    #               description
    #               releaseDate
    #               servingVessels
    #               servingTemperature
    #               primaryFlavorNotes
    #               hintsOfFlavorNotes
    #               isVerified
    #               color
    #               isNewProduct
    #               tastingExperience
    #               abv
    #           }
    #           vessel {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               title
    #               slug
    #               label
    #               shortTitle
    #               tareWeight
    #               couplerWeight
    #               totalVolume
    #               suggestedTotalVolume
    #               material
    #               displayOrder
    #               iconSlug
    #               awrWeight
    #               awrSleep
    #           }
    #           receivedDate
    #           expirationDate
    #           coupler
    #           cooler
    #           initialFluidOz
    #           servingSizes
    #           defaultServingSize
    #           actualKickDate
    #           cost
    #           currentFluidOz
    #           projectedKickDate
    #           depletionPace
    #           depletionRate
    #           depletionStats {
    #               projectedKickDate
    #               depletionPace
    #               depletionRate
    #           }
    #           kegStatus
    #           assignedPositionIndex
    #           isEstimate
    #           isPartial
    #           isTemporary
    #       }
    #       title
    #       slug
    #       numberOfTapSensors
    #       expirationDate
    #       settings {
    #           seatingCapacity
    #           standardHours {
    #               day
    #               isOpen
    #           }
    #           productLifeTracking
    #           coolers
    #           servingSizes
    #           defaultServingSize
    #           couplers
    #           pricingMethod
    #           pricingPercentage
    #           inventoryTurnsGoal
    #           varietyScoreGoal
    #           newProductDefinition
    #           freshnessThreshold
    #           tappedGoal
    #           batteryReplacementPlan
    #           daysOnHandGoal
    #           workWeekStartDay
    #           timeZone
    #           isActive
    #       }
    #       address {
    #           street
    #           street2
    #           city
    #           state
    #           country
    #           postalCode
    #           geo {
    #               lat
    #               lon
    #               title
    #           }
    #       }
    #       onlyPriorityBrands
    #       priorityBrands {
    #           id
    #           createdDate
    #           updatedDate
    #           createdBy
    #           lastUpdatedBy
    #           guid
    #           slug
    #           title
    #           description
    #           displayTitle
    #           subtitle
    #           address {
    #               street
    #               street2
    #               city
    #               state
    #               country
    #               postalCode
    #           }
    #           contact {
    #               phone
    #               email
    #           }
    #           traits {
    #               status
    #               brandTypes
    #               brewTypes
    #               isIndependent
    #               isContract
    #           }
    #           website
    #           socialMedia {
    #               platform
    #               handle
    #           }
    #           assets {
    #               id
    #               caption
    #               category
    #               createdBy
    #               lastUpdatedBy
    #               createdDate
    #               updatedDate
    #               tags
    #               shared
    #               path
    #               alt
    #               type
    #               bytes
    #               width
    #               height
    #           }
    #           mediaLibrary {
    #               id
    #               caption
    #               category
    #               createdBy
    #               lastUpdatedBy
    #               createdDate
    #               updatedDate
    #               tags
    #               shared
    #               path
    #               alt
    #               type
    #               bytes
    #               width
    #               height
    #           }
    #           baId
    #           yearEstablished
    #           products {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               guid
    #               title
    #               slug
    #               description
    #               releaseDate
    #               servingVessels
    #               servingTemperature
    #               primaryFlavorNotes
    #               hintsOfFlavorNotes
    #               isVerified
    #               color
    #               isNewProduct
    #               tastingExperience
    #               abv
    #           }
    #           locations {
    #               id
    #               createdDate
    #               updatedDate
    #               createdBy
    #               lastUpdatedBy
    #               guid
    #               slug
    #               title
    #               subtitle
    #               description
    #               timezone
    #               yearOpened
    #               yearClosed
    #               completionPercentage
    #           }
    #       }
    #       locationType
    #   }
    #   description
    #   model
    #   firmware
    #   connectionStatus
    #   status
  }
}`
