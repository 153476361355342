import { DateTime } from "luxon"
import { TimeMapping, getBeginingOfDayTimestamp, getEndOfDayTimestamp } from "utilities"
import { getAPriorDate } from "./getAPriorDate"

/**
 * Function that takes an array of two Date Objects and returns formatted beginning and end of day strings
 * @param dateRange {Date[]} two Date objects in an array
 * @param timezone {string} user time zone associated with PP Sub
 * @returns an object containing a startDate (example: 2024-04-08T00:00:00.000) and an endDate (example: 2024-04-18T23:59:59.000)
 * Currently in use in conjunction with a react-date-picker in PP Analytics Throughput tables.
 */
const getFormattedStartAndEndDateStringsFromADateRange = ({
    dateRange,
    timezone
}: { dateRange: Date[]; timezone: string | undefined }): {
    startDate: string
    endDate: string
} => {
    const userTimeZone = TimeMapping[timezone] || TimeMapping["US___East__Indiana"]
    let startDate
    let endDate

    if (dateRange && dateRange[0] && dateRange[1]) {
        startDate = getBeginingOfDayTimestamp(
            DateTime.fromJSDate(dateRange[0]).setZone(userTimeZone).toString()
        )
        endDate = getEndOfDayTimestamp(
            DateTime.fromJSDate(dateRange[1]).setZone(userTimeZone).toString()
        )
    } else {
        const yesterday = getAPriorDate({
            number: 1,
            timezone
        }).toString()
        startDate = getBeginingOfDayTimestamp(yesterday)
        endDate = getEndOfDayTimestamp(yesterday)
    }

    return {
        startDate,
        endDate
    }
}

export default getFormattedStartAndEndDateStringsFromADateRange
