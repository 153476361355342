import { useGetDiscountPresetsByBMSubIdQuery } from "../../../api"

export const useDiscountPresetsByBMSubId = (bmSubId: string) => {
    const {
        data: discountPresets,
        isLoading: isLoadingDiscountPresets,
        isError: isErrorDiscountPresets,
        isFetching: isFetchingDiscountPresets,
        isSuccess: isSuccessDiscountPresets
    } = useGetDiscountPresetsByBMSubIdQuery({ bmSubId }, { skip: !bmSubId })

    return {
        discountPresets,
        isLoadingDiscountPresets,
        isErrorDiscountPresets,
        isFetchingDiscountPresets,
        isSuccessDiscountPresets
    }
}
