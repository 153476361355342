"use client"
import { useEffect, useMemo, useState } from "react"
import { ProductStatusFilter } from "types"
import { getProductsByStatus } from "utilities"
import { useProductSearchQuery } from "../../api"
import { useProductSearchStore } from "./productSearchStore"

export const useProductSearch = () => {
    const [verifiedProducts, setVerifiedProducts] = useState([])
    const [unverifiedProducts, setUnverifiedProducts] = useState([])
    const { statusFilter, searchTerm, productTypeFilter } = useProductSearchStore()

    const { data, isLoading, isFetching } = useProductSearchQuery({
        searchTerm,
        productTypeFilter
    })

    useEffect(() => {
        if (data) {
            const { verified, unverified } = getProductsByStatus(data)
            setVerifiedProducts(verified)
            setUnverifiedProducts(unverified)
        }
    }, [data])

    const selectedResults = useMemo(() => {
        if (statusFilter === ProductStatusFilter.Verified) {
            return verifiedProducts || []
        } else if (statusFilter === ProductStatusFilter.Unverified) {
            return unverifiedProducts || []
        }
        return data || []
    }, [data, verifiedProducts, unverifiedProducts, statusFilter])

    return {
        selectedResults,
        allResults: data || [],
        verifiedProducts,
        unverifiedProducts,
        isLoading,
        isFetching
    }
}
